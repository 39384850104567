import React from 'react';
import { CTAOne, Section, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';


function CTAOneSection(props) {



  return (

    <Section
      background="#F6FBFE"
      //ContainerWidth=""
      Spacing="40px"
      Container={

        <div className='SectionContainer hrCta'>
          <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
              <CTAOne
                backgroundColor="#FF6200"
                Title="We need your generous donation to support people in need."
                ButtonLebel="MAKE A DONATION"
                link="https://give.deenfund.com/hhcusa"
                target="_blank"
                minHeight="232px"
                arrowIcon={<ArrowForwardRoundedIcon />}

              />
            </Grid>

            <Grid item xs={12} md={6} className="greenCard">
              <CTAOne
                backgroundColor="#33B651"
                Title="We need volunteers for our activity and for many other support."
                ButtonLebel="BECOME A VOLUNTEER"
                link="https://give.deenfund.com/hhcusa"
                target="_blank"
                minHeight="232px"
                arrowIcon={<ArrowForwardRoundedIcon />}

              />
            </Grid>

          </Grid>

        </div>

      }

    />


  );
}


export default CTAOneSection;