import React from 'react';
import { CarouselOne, Section, ImageGridFour  } from "master-react-lib";
import Pattern from "../assets/imgs/./Pattern.svg";

import img_1 from '../assets/imgs/Activities/Antibody-Bklyn2.jpg';
import img_2 from '../assets/imgs/Activities/BM-Depot- Blind Man-Rubel.jpeg';
import img_3 from '../assets/imgs/Activities/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Volunteer - Copy.png';
import img_4 from '../assets/imgs/Activities/deenconnect-hhc-usa-project-AlManahil-Nurture-General-Hospital .jpg';
import img_5 from '../assets/imgs/Activities/deenconnect-hhc-usa-project-Anowara-1.jpg';
import img_6 from '../assets/imgs/Activities/deenconnect-hhc-usa-project-AntiBody 7.jpg';
import img_7 from '../assets/imgs/Activities/deenconnect-hhc-usa-project-oxygen-concentrator-Anowara.jpg';
import img_8 from '../assets/imgs/Activities/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_BlanketDrive-ParkBlanketDrive-Park.jpeg';
import img_9 from '../assets/imgs/Activities/deenconnect-hhc-usa-project-AntiBody-Arif.jpg';
import img_10 from '../assets/imgs/Activities/deenconnect-hhc-usa-project-Hathazary-OxygenConcentrator.jpg';

function Activities() {


    const images = [img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10,];

    const imgGridData = [
        { img: img_1, },
        { img: img_2, },
        { img: img_3, },
        { img: img_4 },
        { img: img_5 },
        { img: img_6 },
        { img: img_7, },
        { img: img_8, },
        { img: img_9 },
        { img: img_10 },
        
    ];

    const imageClick = (i) => {
        
    }

    const carouseldata = [

        {
            sliderItem: <ImageGridFour
                images={images}
                imageClick={imageClick}
                imgGridData={imgGridData}


            />
        },

        {
            sliderItem: <ImageGridFour
                images={images}
                imageClick={imageClick}
                imgGridData={imgGridData}


            />
        },

        {
            sliderItem: <ImageGridFour
                images={images}
                imageClick={imageClick}
                imgGridData={imgGridData}


            />
        },


    ];



   

    return (
        <Section
            background="#fff"
            //ContainerWidth=""
            Spacing="40px"
            Container={
                <div className='SectionContainer Photogallery'>
                    <CarouselOne
                        title="Activities"
                        carouseldata={carouseldata}
                        mobileview={1}
                        desktopview={1}
                    />

                </div>
            }
        />
    );
}


export default Activities;