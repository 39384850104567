import React from 'react';
import { UserAvatarOne, Section, } from "master-react-lib";
import user_1 from '../assets/imgs/user_1.png';
import user_2 from '../assets/imgs/user_2.png';
import user_3 from '../assets/imgs/user_3.png';
import user_4 from '../assets/imgs/user_4.png';
import user_5 from '../assets/imgs/user_5.png';


function BoardofAdvisors() {
    const guestCardOneData = [

        {
            img: user_1,
            name: "Abdullah al-Razi",
            Designation: "Executive Board - Usa",
        },
        {
            //img: img_2,
            name: "Muhammad Raihan",
            Designation: "Executive Board - Bangladesh",
        },
        {
            img: user_2,
            name: "Muhammad Raihan",
            Designation: "Chief Scientific Officer",
        },

        {
            img: user_3,
            name: "Muhammad Raihan",
            Designation: "Board Of Advisory",
        },

        {
            img: user_4,
            name: "Abdullah al-Razi",
            Designation: "Executive Board - Usa",
        },
        {
            img: user_1,
            name: "Muhammad Raihan",
            Designation: "Executive Board - Bangladesh",
        },
        {
            img: user_2,
            name: "Muhammad Raihan",
            Designation: "Chief Scientific Officer",
        },

        {
            img: user_5,
            name: "Muhammad Raihan",
            Designation: "Board Of Advisory",
        },

    ];



    // return (
    //     <Section
    //         background="#F6FBFE"
    //         //ContainerWidth=""
    //         Spacing="40px"
    //         Container={
    //             <div className='SectionCtn'>
    //                 <h1 className='TextCetner TextDard'>Board of Advisors</h1>
    //                 <UserAvatarOne
    //                     guestCardOneData={guestCardOneData}
    //                     col="3"
    //                     spacing="2"
    //                     width=""
    //                     height="250px"

    //                 />

    //             </div>
    //         }
    //     />
    // );
}


export default BoardofAdvisors;