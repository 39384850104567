import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";
// import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
// import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import Header from "./Header";
import Footer from "./Footer";
// import FacebookIcon from "../assets/imgs/socialicons/FacebookRounded.svg";
// import ShareIcon from "../assets/imgs/socialicons/ShareIcon.svg";
// import TwitterIcon from "../assets/imgs/socialicons/TwitterRounded.svg";
// import WhatsAppIcon from "../assets/imgs/socialicons/WhatsappRounded.svg";
import Container from "@material-ui/core/Container";
// import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
// import Banner from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Covid19.png";
// import articleImg from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Covid19.png";
import CTAOneSection from "./CTAOneSection";
import { Section, PageTitleOne } from "master-react-lib";
import { getSingleNews } from "./Functions";

import "../assets/css/style.css";

function ArticleDetailsPage(props) {
  const [additionalContent, setAdditionalContent] = useState({
    text: [],
    image: [],
    video: [],
  });
  const [newsData, setNewsData] = useState({});
  const [description, setDescription] = useState("");
  const [additionalText, setAdditionalText] = useState([]);

  useEffect(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("q");

    let news = await getSingleNews(term);

    if (news && news.data.body && news.data.body.data) {
      setNewsData(news.data.body.data);
      const disc = JSON.parse( news.data.body.data.description );
      let des = '' ; 
      // console.log( disc )
      for( let i=0; i< disc.length ; i++ ){
          des += disc[i].children[0].text 
          des += '\n\r'
      }

      setDescription(des);
      const additionalTextContent =
        news?.data?.body?.data?.additionalContent?.filter(
          (e) => e.type === "text"
        );
      const additionalVideoContent =
        news?.data?.body?.data?.additionalContent?.filter(
          (e) => e.type === "video"
        );
      const additionalImageContent =
        news?.data?.body?.data?.additionalContent?.filter(
          (e) => e.type === "image"
        );
      console.log('here iam ')
      console.log( additionalTextContent);
      des = [] ;
      // for( i=0 ; i<additionalTextContent.length ; i++){

      //   let aTc = JSON.parse( additionalTextContent[i].value );   
      //   let actualText = '' ; 

      //   console.log( aTc );

      //   for( let i=0; i<aTc.length ; i++ ){

      //     actualText += ( aTc[i].children[0].text );

      //   }
      //   des.push( actualText );

      // }
      
      console.log( des );
      setAdditionalText(des );
      setAdditionalContent({
        text: additionalTextContent,
        image: additionalImageContent,
        video: additionalVideoContent,
      });
    }
  }, []);

  return (
    <div>
      <Header />

      <div className="InnerPageCtn">
        <PageTitleOne
          Title={newsData.title}
          //   SubTitle="By Sheikh Abdullah Raihan"
          color="#fff"
          backgroundcolor="#33B651"
          height="400px"
        />

        <div className="AboutUSbanner" style={{ marginTop: "-230px" }}>
          <img src={newsData?.imageMetaData?.imageUrl} />
        </div>

        <Container maxWidth="lg">
          <div className="InnerPageContentSection">
            <Grid container spacing={3}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="ArticleContentsContainer">
                  <Typography
                    className="articleperagraph"
                    variant="body1"
                    gutterBottom
                  >
                    {description}
                  </Typography>

                  {additionalContent.image &&
                    additionalContent.image.map(
                      (image, index) =>
                        index === 0 && (
                          <div className="articleImg">
                            <img src={image.value.imageUrl} />
                          </div>
                        )
                    )}

                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 0 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {additionalText[0]}
                          </Typography>
                        )
                      );
                    })}

                  {additionalContent.video &&
                    additionalContent.video.map((video, index) => {
                      return (
                        index === 0 && (
                          <div className="youtubevideo">
                            <iframe
                              width="100%"
                              height="358"
                              src={video.value.slice(1, -1)}
                              title={"YouTube video player"}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )
                      );
                    })}

                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 1 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {/* <FiberManualRecordRoundedIcon /> */}
                            {additionalText[1]}
                          </Typography>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.image &&
                    additionalContent.image.map(
                      (image, index) =>
                        index === 1 && (
                          <div className="articleImg">
                            <img src={image.value.imageUrl} />
                          </div>
                        )
                    )}
                  <br />
                  <br />
                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 2 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {/* <FiberManualRecordRoundedIcon /> */}
                            {additionalText[2]}
                          </Typography>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.video &&
                    additionalContent.video.map((video, index) => {
                      return (
                        index === 1 && (
                          <div className="youtubevideo">
                            <iframe
                              width="100%"
                              height="358"
                              src={video.value.slice(1, -1)}
                              title={"YouTube video player"}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 3 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {/* <FiberManualRecordRoundedIcon /> */}
                            {additionalText[3]}
                          </Typography>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.image &&
                    additionalContent.image.map(
                      (image, index) =>
                        index === 2 && (
                          <div className="articleImg">
                            <img src={image.value.imageUrl} />
                          </div>
                        )
                    )}
                  <br />
                  <br />
                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 4 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {/* <FiberManualRecordRoundedIcon /> */}
                            {additionalText[4]}
                          </Typography>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.video &&
                    additionalContent.video.map((video, index) => {
                      return (
                        index === 2 && (
                          <div className="youtubevideo">
                            <iframe
                              width="100%"
                              height="358"
                              src={video.value.slice(1, -1)}
                              title={"YouTube video player"}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 5 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {/* <FiberManualRecordRoundedIcon /> */}
                            {additionalText[5]}
                          </Typography>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 6 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {/* <FiberManualRecordRoundedIcon /> */}
                            {additionalText[6]}
                          </Typography>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.image &&
                    additionalContent.image.map(
                      (image, index) =>
                        index === 3 && (
                          <div className="articleImg">
                            <img src={image.value.imageUrl} />
                          </div>
                        )
                    )}
                  <br />
                  <br />
                  {additionalContent.text &&
                    additionalContent.text.map((text, index) => {
                      return (
                        index === 7 && (
                          <Typography
                            className="articleperagraph"
                            variant="body1"
                            gutterBottom
                          >
                            {/* <FiberManualRecordRoundedIcon /> */}
                            {additionalText[7]}
                          </Typography>
                        )
                      );
                    })}
                  <br />
                  <br />
                  {additionalContent.video &&
                    additionalContent.video.map((video, index) => {
                      return (
                        index === 3 && (
                          <div className="youtubevideo">
                            <iframe
                              width="100%"
                              height="358"
                              src={video.value.slice(1, -1)}
                              title={"YouTube video player"}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )
                      );
                    })}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <CTAOneSection />
      <Footer />
    </div>
  );
}

export default ArticleDetailsPage;
