import React from 'react';
import { CarouselOne, Section, UserAvatarFour } from "master-react-lib";

// import Icom from '../assets/imgs/PartnerIcom.png';
import CRP from '../assets/imgs/ourpartners/CRP.jpeg';
import BewarishSebaFoundation from '../assets/imgs/ourpartners/BewarishSebaFoundation.jpeg';
import ASFFoundation from '../assets/imgs/ourpartners/ashfoundation.jpeg';
import ChittagongAssociationofNorthAmerica from '../assets/imgs/ourpartners/ChittagongAssociationofNorthAmerica.png';


function OurPartners() {




    const carouseldata = [

        {
            sliderItem: <UserAvatarFour img={ASFFoundation} Title="Alhaj Shamsul Hoque Foundation" Designation="" />
        },
        {
            sliderItem: <UserAvatarFour img={BewarishSebaFoundation} Title="Bewarish Seba Foundation" Designation="" />
        },
        {
            sliderItem: <UserAvatarFour img={CRP} Title="CRP" Designation="BANGLADESH" />
        },

        {
            sliderItem: <UserAvatarFour img={ChittagongAssociationofNorthAmerica} Title="Chittagong Association of North America Inc " Designation="" />
        },
        {
            // sliderItem:  <UserAvatarFour  img={Icom} Title="MUNTADA AID" Designation="Global Partner" />
        },
        // {
        //     sliderItem:  <UserAvatarFour  img={Icom} Title="MUNTADA AID" Designation="Global Partner" />
        // },




    ];


    return (
        <Section
            background="#fff"
            //ContainerWidth=""
            Spacing="40px"
            Container={
                <div className='OurPartner hideArros'>
                    <CarouselOne
                        title="Our Partners"
                        carouseldata={carouseldata}
                        mobileview={5}
                        desktopview={5}
                    />

                </div>
            }
        />
    );
}


export default OurPartners;