import React from 'react';
import Header from './Header'
import Footer from './Footer'
import Grid from '@material-ui/core/Grid';
import BangladeshFlag from '../assets/imgs/Bangladesh-Flag.jpg';
import UsaIcon from '../assets/imgs/Usa-Icon.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import img_1 from '../assets/imgs/program2/deenconnect-hhc-usa-BM-Depot- Blind Man-Rubel.jpeg';
import img_2 from '../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Giving-CheckGiving-Check.jpg';
import img_3 from '../assets/imgs/program1/deenconnect-hhc-usa-project-fire-explosion BM-Depot-Sujon-Baharl.jpeg';
import img_4 from '../assets/imgs/program2/deenconnect-hhc-usa-turkey victims.jpeg';
import img_5 from '../assets/imgs/program1/deenconnect-hhc-usa-project-fire-explosion BM-Depot-Sujon-Baharl.jpeg';
import img_6 from '../assets/imgs/deenconnect-hhc-usa-Health-Ambulence-Service.png';
import img_7 from '../assets/imgs/program1/deenconnect-hhc-usa-project-fire-explosion BM-Depot-Sujon-Baharl.jpeg';

import { Section, PageTitleOne, CardFifteen, ModalFour } from "master-react-lib";
import CTAOneSection from './CTAOneSection'
import Activities from './Activities'
import VideoFromTheField from './VideoFromTheField'
import Projects from './Projects'

import '../assets/css/style.css'

function DisasterManagementProgramDetailsPage(props) {

  const [open_1, setOpen_1] = React.useState(false);
  const handleOpen_1 = () => setOpen_1(true);
  const handleClose_1 = () => setOpen_1(false);


  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => setOpen_2(true);
  const handleClose_2 = () => setOpen_2(false);



  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => setOpen_3(true);
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);

  const [open_5, setOpen_5] = React.useState(false);
  const handleOpen_5 = () => setOpen_5(true);
  const handleClose_5 = () => setOpen_5(false);

  const usaProjectsUsa = [
    {
      img: img_1,
      titleTop: "",
      flag: BangladeshFlag,
      titleTopLink: "/programt-details",
      title: "BM Deport Disaster : Rubel , a victim of BM Depot Fire Explotion",
      description: "Helping Hand Chattogram inc, USA extended a helping hand to...",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_1,
    },

    {
      img: img_2,
      titleTop: "",
      flag: BangladeshFlag,
      title: "BM Deport Disaster : Md. Shahadat Hossain, a covered  ",
      description: "BM Deport Disaster : Md. Shahadat Hossain, a covered van... ",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_2,
    },

    {
      img: img_3,
      titleTop: "",
      flag: BangladeshFlag,
      title: "BM Deport Disaster: Shujan at Chottagram Medical College ",
      description: "সীতাকুণ্ড বিএম ডিপোতে অগ্নিকাণ্ডে অগ্নিদগ্ধ চমেক হাসপাতালে চিকিৎসাধীন...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_3,
    },

    {
      img: img_4,
      titleTop: "",
      flag: UsaIcon,
      title: "Helping Hand Chattogram - USA is standing next to the victims",
      description: "HHC team are deeply saddened by the devastating catastrophe that...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_4,
    },

    // {
    //   img: img_5,
    //   titleTop: "",
    //   flag: UsaIcon,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: "UPCOMING PROJECT:  Mobile medical units bring relief ",
    //   link: "#",
    //   Donationbtnlink: "https://give.deenfund.com/hhcusa",
    //   onClick: handleOpen_5,
    // },

    // {
    //   img: img_6,
    //   titleTop: "",
    //   flag: UsaIcon,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: "UPCOMING PROJECT:  Mobile medical units bring relief to  ",
    //   link: "#",
    //   Donationbtnlink: "https://give.deenfund.com/hhcusa",
    //   onClick: handleOpen_5,
    // },

  ];
  const eventDetails = [
    {
      img: img_1,
      title: "Artifical Limb Support",
      Location: "Location: Bangladesh",
      description: <p>Helping Hand Chattogram inc, USA extended a helping hand to those killed and injured in the BM depot fire at Sitakunda.<br>
      </br>Md. Shahadat Hossain, the covered van driver of Zorarganj Union who died in the fire explotion, HSC team visited the victim's house in a rural arae of  Saidali village of Khaiachhra Union and inquired about the family. Shahadat Hossain's mother handed over a donation check of Tk 20,000 Helping Hand Chattogram-USA'
        <br></br><br></br>সীতাকুণ্ড'র বিএম ডিপোর অগ্নিকান্ডে নিহত ও আহতদের জন্য সাহায্যের হাত বাড়ালো Helping Hand Chattogram inc, USA।
        অগ্নিকাণ্ডে নিহত জোরারগঞ্জ ইউনিয়নের কাভার্ড ভ্যান চালক মোঃ শাহাদাত হোসেন এবং খৈয়াছড়া ইউনিয়নের সৈদালী গ্রামের আহত মোঃ রুবেলের বাড়িতে গিয়ে পরিবারের খোঁজ খবর নেন।রুবেলের হাতে অনুদানের চেক তুলে দেন Helping Hand Chattogram-USA'
        <br></br>পরিবার গুলো মানবেতর জীবন যাপন করছে। চিকিৎসার অভাবে আহত রুবেলের চোখের দৃষ্টিশক্তি হারিয়ে যাচ্ছে। পানি ঝরছে অনবরত। মা বাবার একমাত্র সন্তান রুবেল। তার ঘরে রয়েছে ফুটফুটে একটি ছোট্ট শিশু। পরিবারের ভবিষ্যত এবং নিজের জীবন নিয়ে রুবেলের যতো দুশ্চিন্তা । উন্নত চিকিৎসা না পেলে দুটি চোখে মা, আদরের সন্তান আর সুন্দর পৃথিবী সে দেখবেনা।

      </p>,
      open: open_1,
      handleClose: handleClose_1,
    },
    {

      img: img_2,
      title: "BM Deport Disaster : Md. Shahadat Hossain, a covered van driver of Zorarganj Union who died in the fire,",
      description: <p>Covered van driver killed in the fire explotion at BM Depot .Helping Hand team visted  Abul Hashem's Sitakunda house (Masjida, Kumira) and handing over a donation check of TK. 25000/- to his  three children.
        <br></br><br></br>সীতাকুণ্ড'র বিএম ডিপোর অগ্নিকান্ডে নিহত ও আহতদের জন্য সাহায্যের হাত বাড়ালো Helping Hand Chattogram inc, USA.. অগ্নিকাণ্ডে নিহত কাভার্ড ভ্যান চালক মো. আবুল হাসেম-এর সীতাকুণ্ডস্থ বাড়িতে( মসজিদ্দা,কুমিরা) গিয়ে তিন সন্তানের হাতে অনুদানের ২৫০০০/- টাকার চেক তুলে দিচ্ছেন Helping Hand Chattogram-USA'র .অনুদানের চেক হাতে তুলে দিতে গিয়ে মরহুমের মেয়েদের চোখের পানি দেখে আমাদেরকেও আবেগাপ্লুত হতে হয়েছিল।
      </p>,
      open: open_2,
      handleClose: handleClose_2,
    },
    {

      img: img_3,
      title: "BM Deport Disaster: Shujan at Chottagram Medical College Hospital",
      description: <p> সীতাকুণ্ড বিএম ডিপোতে অগ্নিকাণ্ডে অগ্নিদগ্ধ চমেক হাসপাতালে চিকিৎসাধীন মো. সুজনের ( বাড়ি- বাহুবল, হবিগঞ্জ) হাতে আর্থিক সহায়তা তুলে দিচ্ছেন  বিশিষ্ট মুক্তিযোদ্ধা জনাব দেলোয়ার হোসাইন  সুজনকে খুঁজে বের করতে সহায়তা করেছেন আরেক মানবতার ফেরিওয়ালা, লেখক,সমাজকর্মী ব্রাদার বাহার । ব্রাদার বাহার অসাধারণ একজন মানবতাবাদি মানুষ। মানুষের দূঃখে তিনি ঝাঁপিয়ে পড়েন অসীম আত্মবিশ্বাস নিয়ে।</p>,
      open: open_3,
      handleClose: handleClose_3,
    },
    {

      img: img_4,
      title: "Helping Hand Chattogram - USA is standing next to the victims  of Turkey and Syria, the worst earthquake in history ",
      description: <p>HHC team are deeply saddened by the devastating catastrophe that occurred in Turkey and Syria,  Helping Hand Chattogram - USA is standing next to the victims  of Turkey and Syria, the worst earthquake in history with relief , collaborate with  the International Relief Organization HHRD.

        <br></br> Our heartfelt condolences go out to the families of the victims and our thoughts are with the loss of life and destruction of livelihoods. May Allah make it easy for all the people affected  by the earthquake
        <br></br> <br></br>তুরস্ক ও সিরিয়ায় ইতিহাসের স্মরণকালের ভয়াবহ ভূমিকম্পে
        আহত ও গৃহহীন মানুষের মাঝে  সাধ্য অনুযায়ী সামান্য ত্রাণ সামগ্রী নিয়ে পাশে দাঁড়িয়েছে Helping Hand Chattogram - USA.  আন্তর্জাতিক রিলিফ অর্গানাইজেশন HHRD এর মাধ্যমে পৌঁছে গেছে বেশ কিছু ত্রাণ। </p>,
      open: open_4,
      handleClose: handleClose_4,
    },

    // {

    //   img: img_5,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: <p>UPCOMING PROJECT:  Mobile medical units bring relief to villagers who are either unable to afford medical care or reside in rural areas that oftentimes are unreachable during natural disasters. Emergency medical professionals, enabled with ambulatory training, increase their capacity to cope with any given emergency through these services. This also helps provide a means for patients to successfully attend rehabilitation and therapy sessions by securing transportation. HHC have a future plan to provide Mobile Medical Unit & Ambulance Services,</p>,
    //   open: open_5,
    //   handleClose: handleClose_5,
    // },
    // {

    //   img: img_6,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: <p>UPCOMING PROJECT:  Mobile medical units bring relief to villagers who are either unable to afford medical care or reside in rural areas that oftentimes are unreachable during natural disasters. Emergency medical professionals, enabled with ambulatory training, increase their capacity to cope with any given emergency through these services. This also helps provide a means for patients to successfully attend rehabilitation and therapy sessions by securing transportation. HHC have a future plan to provide Mobile Medical Unit & Ambulance Services,</p>,
    //   open: open_5,
    //   handleClose: handleClose_5,
    // },

  ];







  return (

    <div>
      <Header />

      <div className='InnerPageCtn'>
        <PageTitleOne
          Title="Program Details"
          color="#fff"
          backgroundcolor="#33B651"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={

            <div className='SectionContainer'>
              <h1 className='2ndTitle TextCetner'>Disaster Management-Emergency Response</h1>
              <div className='ParaSection'>
                <p><b>HHC is a  humanitarian organization that actively works to provide relief by participating in disaster response in disaster areas. provides social services to the underprivileged and those affected by natural disasters.  </b></p>

                <div className='TopBannerCtn'>
                  <img src={img_7} />

                </div>

                <p>We strive to provide immediate response and aid to disasters stricken areas in Bangladesh and USA. Whenever there is a disaster, be it natural or manmade, there is always a human impact. <b> Helping Hand Chattogram-USA  </b>launches emergency appeal in response to a  massive fire swept through an inland container facility depot 40 km (25 miles) from the port city of Chittagong, triggering a huge blast and multiple container explosions subsequently, killing at least 50 people and injuring more than 400.
                </p>
                <p>The affected families need urgent help of medicine , treatment , food and other long-term rehabilitation of the affected families is also required. HHC Volunteers physically visited rural areas of victims family and help them directly to their hand. </p>
              </div>
            </div>
          }
        />


        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer'>
              <h1 className='2ndTitle'>Projects </h1>
              <div className='devider'></div>

              <div>

                <CardFifteen
                  cardFifteenData={usaProjectsUsa}
                  col={4}
                  colSpacing={2}
                  titleColor="#fff"
                  Titlebackground="#0B132B"
                  discriptionColor="#707070"
                  buttonLabel="Read More"
                  //ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#FF6200"
                  BottomLineColor="#FF6200"
                  BottomLine={true}
                  border="border"
                  titleTopLeftcolor="#FF6200"

                  // == Button Style =====//
                  label="Make a donation"
                  link="https://www.google.com/"
                  target="blank"
                  path="/"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#33B651"
                  width="100%"
                  height="50px" slider
                  borderRadius="0px"
                  color="#fff"
                  fontWeight="600"
                  //textAlign="center"
                  Buttonlink="https://give.deenfund.com/hhcusa"
                />

              </div>




            </div>
          }
        />





        <Activities />
        <VideoFromTheField />
        <Projects />
        <CTAOneSection />
      </div>

      {eventDetails.map((item, i) => (



        <ModalFour
          open={item.open}
          handleClose={item.handleClose}
          width="910px"
          height="750px"
          title="Project details"
          img={item.img}
          imgstatus={true}
          // location="New York, United States"
          // Program="Covid-19"
          // Programlink="/programt-details"

          buttonlabel="DONATE NOW"
          buttoncolor="#33B651"
          buttonarrowStatus="true"
          buttonarrowColor="#fff"
          color="#fff"
          buttonlink="https://give.deenfund.com/hhcusa"
          target="_blank"
          buttonwidth="80%"
          buttonheight="40px"
          ButtonRadius="4px"
          textAlign="center"

          // LocationStatus={true}
          // eventTitle="Personal protective equipment (PPE)"
          // eventTitleStatus={true}



          body={
            <div>
              <h2>{item.title}</h2>
              {/* <h3>{item.Location}</h3> */}
              <p>{item.description}</p>
            </div>
          }

        />
      ))}



      <Footer />

    </div>

  );
}


export default DisasterManagementProgramDetailsPage;