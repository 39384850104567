import React from "react";
import { Section, CardEleven } from "master-react-lib";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import Grid from "@material-ui/core/Grid";
import img_1 from "../assets/imgs/deenconnect-hhc-usa-project-limb-HomePage.png";
import img_2 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Giving-CheckGiving-Check.jpg";
import img_3 from "../assets/imgs/deenconnect-hhc-usa-Hospitals.png";
import img_4 from "../assets/imgs/deenconnect-hhc-usa-project-wheel-chair-amjad-hossain-1.jpeg";
import img_5 from "../assets/imgs/BlanketDrive-ArifDaughter.jpeg";
import img_6 from "../assets/imgs/hhc-usa-project-Antibody-testing.png";

import "../assets/css/style.css";

function Projects() {
  const cardElevenData = [
    {
      img: img_1,
      title: "Artificial Limb Support",
      link: "/artificial-limb-support-program-details",
    },

    {
      img: img_2,
      title: "Disaster Management-Emergency Response",
      link: "/disaster-management-program-details",
    },
    {
      img: img_3,
      title: "Medical Supports",
      link: "/medical-support-program-details",
    },

    {
      img: img_4,
      title: "Wheelchair for Handicapped",
      link: "/wheel-chair-for-handicapped-program-details",
    }, {
      img: img_5,
      title: "Homeless Assistance and Hunger Prevention",
      link: "/winter-assistance-and-hunger-program-details",
    }, {
      img: img_6,
      title: "Education Support-Scholarship  Program",
      link: "/education-support-program-details",
    },
  ];

  return (
    <Section
      background="#fff"
      //ContainerWidth=""
      Spacing="40px"
      Container={
        <div className="SectionContainer">
          <h4 className="TopTitle">  Anytime. Anywhere. Anyone in Need.
          </h4>
          <h1 className="SecTitle">OUR PROGRAMS/PROJECTS</h1>
          <div className="devider"></div>
          <div>
            <Grid container spacing={3}>
              {cardElevenData.map((item, i) => (
                <Grid item xs={12} md={6}>
                  <CardEleven
                    img={item.img}
                    title={item.title}
                    link={item.link}
                    titleColor="#fff"
                    Titlebackground="#0B132B"
                    buttonLabel="Learn More"
                    ReadmoreIcon={<ArrowForwardRoundedIcon />}
                    ReadmoreColor="#33B651"
                  // ReadmoreColor="#FF6200"
                  // BottomLineColor="#FF6200"
                  // BottomLine={true}
                  // border="underline"
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      }
    />
  );
}

export default Projects;
