import React from 'react';
import { CTAEight, ModalThree } from "master-react-lib";

import Df from '../assets/imgs/df-donation-button.svg';
// import Paypal from '../assets/imgs/paypal.svg';
import Zelle from '../assets/imgs/Zelle.png';
import Chase from '../assets/imgs/Chase.png';


function CTAEightSection(props) {

  const [OpenZelle, setOpenZelle] = React.useState(false);
  const handleOpenZelle = () => setOpenZelle(true);
  const ZellehandleClose = () => setOpenZelle(false);

  const [OpenQuickPay, setOpenQuickPay] = React.useState(false);
  const handleOpenQuickPay = () => setOpenQuickPay(true);
  const QuickPayhandleClose = () => setOpenQuickPay(false);

  const [OpenSendCheck, setOpenSendCheck] = React.useState(false);
  const handleSendCheck = () => setOpenSendCheck(true);
  const SendCheckhandleClose = () => setOpenSendCheck(false);




  const cTAEightData = [
    { icon: Df, link: "https://give.deenfund.com/hhcusa", target: "_blank" },
    // { icon: Paypal, link: "https://www.paypal.com/us/signin" },

    { icon: Zelle, onClick: handleOpenZelle, },
    { icon: Chase, onClick: handleOpenQuickPay, },
    { lebel: "SEND CHECK BY MAIL", onClick: handleSendCheck, },
  ];

  return (

    <div>
      <CTAEight
        cTAEightData={cTAEightData}
        backgroundColor="#2DA047"
        backgroundColorRight="#33B651"
        color="#fff"
        btnlebelcolor="#33B651"

        Title="MANY WAYS TO SUPPORT HHC"
        SubTitle="Al-Tirmidhi"
        ButtonLebel="DONATE NOW"
        link=""
        target="blank"
        minHeight="250px"

        //=== left col content ===/
        TitleLeft="SUPPORT HHC"
        LeftDescription="The Prophet ﷺ said, the most beloved of deeds to Allah are those that are most consistent, even if it is small."
        bookname="(Sahih Bukhari)"
        LeftBottomText="Please donate $5-$10/ every month Auto recurring by DEENFUND"

      />


      <ModalThree
        open={OpenZelle}
        handleClose={ZellehandleClose}
        width="660px"
        title="DONATE VIA ZELLE"
        titileColor="#33B651"

        body={
          <div>
            <p>ZELLE EMAIL</p>
            <a href="#">HelpCtg.USA@gmail.com</a>
          </div>
        }

      />

      <ModalThree
        open={OpenQuickPay}
        handleClose={QuickPayhandleClose}
        width="660px"
        title="DONATE VIA CHASE QUICKPAY"
        titileColor="#33B651"

        body={
          <div>
            <p>EMAIL</p>
            <a href="#">HelpCtg.USA@gmail.com</a>
          </div>
        }

      />

      <ModalThree
        open={OpenSendCheck}
        handleClose={SendCheckhandleClose}
        width="660px"
        title="SEND CHECK BY MAIL"
        titileColor="#33B651"

        body={
          <div>
            <p>Address:</p>
            <a href="#"> 73 Mackay PL <br></br>Brooklyn, NY 11209</a>
          </div>
        }

      />
    </div>




  );
}


export default CTAEightSection;