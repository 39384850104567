import React from 'react';
import { Section, ButtonOne } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Grid from '@material-ui/core/Grid';
import '../assets/css/style.css'

function Projects() {




    return (
        <Section
            background="#F6FBFE"
            //ContainerWidth=""
            Spacing="40px"
            Container={
                <div className='SectionContainer'>

                    <Grid container spacing={3}>

                        <Grid item md={6} sm={6} xs={12}>
                            <div className='aboutusContents'>
                                <h1>About Us</h1>
                                <div className='devider'></div>
                                <h1>Helping Hand For Chittagonian</h1>
                                <p>Helping Hand For Chittagonian INC. (HHC), DBA: Helping Hand Chattogram-USA is a US base 501 (c)(3) Not for Profit organization that provides emergency aid, medical equipments to Covid 19 Victims , Education support, Disaster management, Feed the hunger, Rehabilitation program, Developmental assistance, and services to vulnerable populations in Bangladesh as well as in the USA. Our project helps to raise Community Awareness, Medical Assistance, Education Activities and support emergency aid to Covid 19 Victims in home and abroad.</p>

                                <div className='BtnGroup'>
                                    <ButtonOne
                                        label="READ MORE ABOUT US"
                                        link="/about-us"
                                        //target="blank"
                                        path="/about-us"
                                        arrowIcon={<ArrowForwardRoundedIcon />}
                                        arrowStatus={true}
                                        arrowColor="#fff"
                                        buttoncolor="#FF6200"
                                        width="auto"
                                        height="50px"
                                        border="#ccc 0px solid"
                                        borderRadius="4px"
                                        color="#fff"
                                        fontWeight="600"
                                    //textAlign="center"
                                    />

                                    <ButtonOne
                                        label="SUPPORT US"
                                        link="https://give.deenfund.com/hhcusa"
                                        target="_blank"
                                        arrowIcon={<ArrowForwardRoundedIcon />}
                                        arrowStatus={true}
                                        arrowColor="#FF6200"
                                        buttoncolor="#fff"
                                        width="auto"
                                        height="50px"
                                        border="#FF6200 1px solid"
                                        borderRadius="4px"
                                        color="#FF6200"
                                        fontWeight="600"
                                    //textAlign="center"
                                    />
                                </div>
                            </div>

                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <div class="fb-pageContainer">
                                <div class="fb-page" data-href="https://www.facebook.com/hhc.usa?_rdc=1&amp;_rdr" data-tabs="timeline" data-width="500" data-height="480" data-small-header="true" data-adapt-container-width="false" data-hide-cover="true" data-show-facepile="true"><blockquote cite="https://www.facebook.com/hhc.usa?_rdc=1&amp;_rdr" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/hhc.usa?_rdc=1&amp;_rdr">Helping Hand For Chittagonian</a></blockquote></div>
                            </div>
                        </Grid>

                    </Grid>
                </div>
            }
        />
    );
}


export default Projects;