import React from 'react';
import { CarouselOne, Section, VideoCardOne } from "master-react-lib";

import Icom from '../assets/imgs/PartnerIcom.png';
import SubscriptionsRoundedIcon from '@material-ui/icons/SubscriptionsRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import img_1 from '../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Covid19.png';
import img_2 from '../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Limbs_&_Disability_Support.png';
import img_3 from '../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Scholarship.png';
import img_4 from '../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Volunteer.png';

function VideoFromTheField() {




    // const carouseldata = [

    //     {
    //         sliderItem:
    //             <VideoCardOne
    //                 img={img_1}
    //                 title="Nongor TV- নোঙ্গর টিভি- লাইভ টক শো"
    //                 link="https://www.youtube.com/channel/UCqmnHK6rGy8lLA6LPvpqImg"
    //                 titleColor="#fff"
    //                 Titlebackground="#0B132B"
    //                 buttonLabel="View Details"
    //                 ReadmoreIcon={<ArrowForwardRoundedIcon />}
    //                 ReadmoreColor="#00D4FF"
    //                 BottomLineColor="#FF6200"
    //                 BottomLine={true}
    //                 border="underline"
    //                 icon={<SubscriptionsRoundedIcon />}
    //             />
    //     },

    //     {
    //         sliderItem:
    //             <VideoCardOne
    //                 img={img_1}
    //                 title="CPlus TV- চট্টগ্রামের জনপ্রিয় সি প্লাস টিভি কর্তৃক ' হেল্পিং হ্যান্ড ফর চিটাগোনিয়ান' এর ১ম "
    //                 link="https://www.facebook.com/hhc.usa/videos/216870659594534"
    //                 titleColor="#fff"
    //                 Titlebackground="#0B132B"
    //                 buttonLabel="View Details"
    //                 ReadmoreIcon={<ArrowForwardRoundedIcon />}
    //                 ReadmoreColor="#00D4FF"
    //                 BottomLineColor="#FF6200"
    //                 BottomLine={true}
    //                 border="underline"
    //                 icon={<SubscriptionsRoundedIcon />}
    //             />
    //     },


    //     {
    //         sliderItem:
    //             <VideoCardOne
    //                 img={img_2}
    //                 title="CRB Issue: যমুনা টিভি "
    //                 link="https://www.facebook.com/100004732005382/videos/189436229889518/"
    //                 titleColor="#fff"
    //                 Titlebackground="#0B132B"
    //                 buttonLabel="View Details"
    //                 ReadmoreIcon={<ArrowForwardRoundedIcon />}
    //                 ReadmoreColor="#00D4FF"
    //                 BottomLineColor="#FF6200"
    //                 BottomLine={true}
    //                 border="underline"
    //                 icon={<SubscriptionsRoundedIcon />}
    //             />
    //     },


    //     {
    //         sliderItem:
    //             <VideoCardOne
    //                 img={img_3}
    //                 title="সন্দ্বীপ নিউজ এজেন্সি | Daily Sandwip"
    //                 link="https://www.facebook.com/daily.sandwip.7/videos/584426868912254?idorvanity=327598584943070"
    //                 titleColor="#fff"
    //                 Titlebackground="#0B132B"
    //                 buttonLabel="View Details"
    //                 ReadmoreIcon={<ArrowForwardRoundedIcon />}
    //                 ReadmoreColor="#00D4FF"
    //                 BottomLineColor="#FF6200"
    //                 BottomLine={true}
    //                 border="underline"
    //                 icon={<SubscriptionsRoundedIcon />}
    //             />
    //     },


    // ];


    return (
        // <Section
        //     background="#F6FBFE"
        //     //ContainerWidth=""
        //     Spacing="40px"
        //     Container={
        //         <div className='OurVideo'>
        //             <CarouselOne
        //                 title="Video From The Field"
        //                 carouseldata={carouseldata}
        //                 mobileview={3}
        //                 desktopview={3}
        //             />

        //         </div>
        //     }
        // />
        <></>
    );
}


export default VideoFromTheField;