import React from 'react';
import Header from './Header'
import Footer from './Footer'
import Grid from '@material-ui/core/Grid';
import BangladeshFlag from '../assets/imgs/Bangladesh-Flag.jpg';
import UsaIcon from '../assets/imgs/Usa-Icon.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import img_1 from '../assets/imgs/Ourprojects/deenconnect-hhc-usa-Ashrafi Khatun-14.jpeg';
import img_2 from '../assets/imgs/program1/deenconnect-hhc-usa-Amputee.jpeg';
import img_3 from '../assets/imgs/program1/deenconnect-hhc-আবু হানিফ-Group.jpeg';
import img_4 from '../assets/imgs/program1/deenconnect-hhc-usa-ArmanUddin.jpeg';
import img_5 from '../assets/imgs/program1/deenconnect-hhc-usa-মোহাম্মদ আলম.jpeg';
import img_6 from '../assets/imgs/program1/deenconnect-hhc-usa-রায়পুর গ্রামের জনাব শওকত.jpeg';
import img_7 from '../assets/imgs/program1/deenconnect-hhc-usa-project-limb.jpeg';


import { Section, PageTitleOne, CardFifteen, ModalFour } from "master-react-lib";
import CTAOneSection from './CTAOneSection'
import Activities from './Activities'
import VideoFromTheField from './VideoFromTheField'
import Projects from './Projects'

import '../assets/css/style.css'

function ArtificialLimbSupportProgramDetailsPage(props) {

  const [open_1, setOpen_1] = React.useState(false);
  const handleOpen_1 = () => setOpen_1(true);
  const handleClose_1 = () => setOpen_1(false);


  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => setOpen_2(true);
  const handleClose_2 = () => setOpen_2(false);



  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => setOpen_3(true);
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);

  const [open_5, setOpen_5] = React.useState(false);
  const handleOpen_5 = () => setOpen_5(true);
  const handleClose_5 = () => setOpen_5(false);

  const [open_6, setOpen_6] = React.useState(false);
  const handleOpen_6 = () => setOpen_6(true);
  const handleClose_6 = () => setOpen_6(false);

  const usaProjectsUsa = [
    {
      img: img_1,
      titleTop: "",
      flag: BangladeshFlag,
      titleTopLink: "/programt-details",
      title: "Ashrafi Khatun-9th grade Student",
      description: "চাঁপাইনবাবগঞ্জ জেলার শিবগঞ্জ উপজেলার কানসাট(ক‍্যাইঠাপাড়া) অটো চালক... ",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_1,
    },

    {
      img: img_2,
      titleTop: "",
      flag: BangladeshFlag,
      title: " Yousuf Ali - A Masters student of Pabna Adward  College",
      description: " Yousuf Ali - A Masters student of Pabna Adward  College...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_2,
    },

    {
      img: img_3,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Abu Hanif- A Engeen Boat Driver, Bashkali, Chattogram",
      description: "Abu Hanif- A Engeen Boat Driver, Bashkali, Chattogram...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_3,
    },

    {
      img: img_4,
      titleTop: "",
      flag: BangladeshFlag,
      title: "ARMAN UDDIN – 10th Grade Student from  Fatikchari",
      description: "১১ বছর ধরে পা নেই চট্টগ্রাম ফটিকছড়ি আজিম নগরের দশম শ্রেণীর ছাত্র...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_4,
    },

    {
      img: img_5,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Road Accident Victim Mohammed Alam, Anowara",
      description: "Mohammad Alam (Anwara Upazila),  the  boy who are standing on the...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_5,
    },

    {
      img: img_6,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Road Accident Victim Shawkat, Anowara, Chottagram",
      description: " Helping Hand Chattogram inc USA এর পক্ষ থেকে আজ আনোয়ারার প্রত্যন্ত...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_6,
    },


  ];


  const eventDetails = [
    {
      img: img_1,
      title: "Ashrafi Khatun-9th grade Student, Shibganj, Chapai Nawabbganj",
      Location: "Location: Bangladesh",
      description: <p> চাঁপাইনবাবগঞ্জ জেলার শিবগঞ্জ উপজেলার কানসাট(ক‍্যাইঠাপাড়া) অটো চালক মোহাম্মদ বাবুর মেয়ে মোসাম্মাৎ আশরাফী খাতুন (14) এবার নবম শ্রেণিতে পড়ালেখা করছেন। সাত বছর বয়সে ভুল চিকিৎসার কারনে তার ডান পা উরু থেকে কেটে ফেলেন। ডাক্তার সেই থেকে লাঠিতে ভর দিয়েই চলাফেরা করে আসছিলেন। উনি মানবিক শওকত হোসেন ভাইয়ের সাথে যোগাযোগ করে হেল্পিং হেন্ডের অর্থায়নে সম্পূর্ণ ফ্রীতে কৃত্রিম পা সংযোজন করে দেয়। আনন্দে আত্মহারা আশরাফির পা সংযোজন শেষে ২২ দিন পর চট্টগ্রাম থেকে তার পরিবারের কাছে ফিরে এসেছে নতুন উদ্দোমে।
        <br></br>
        অনেক দিনের স্বপ্ন চাঁপাইনবাবগঞ্জের নবম শ্রেণীর স্কুল ছাত্রী আশরাফি খাতুন (১৪) ক্রাচে ভর না করে পায়ে হেটে স্কুলে যাবেন , নতুন বছরে শুরুতে নতুন উদ্দোমে ক্রাচ ছাড়া বাড়ী ফিরল আশরাফি।
      </p>,
      open: open_1,
      handleClose: handleClose_1,
    },
    {

      img: img_2,
      title: "Yousuf Ali - A Masters student of Pabna Adward  College.  Pabna",
      description: <p> ২৫ বছর ধরে পা নেই  ঈশ্বরদী থেকে আগত পাবনা এডওয়ার্ড কলেজের মাস্টার্স পড়ুয়া মুহাম্মদ ইউসুফ আলীর ।    <br /><br />
        তার জন্ম থেকেই একটি পা হাটুর নিচ থেকে ছিল না,সে এক পা দিয়ে ক্রাচের সাহায্যে চলাফেরা করে বর্তমানে মাস্টার্সে পড়াশোনা করছে,কিন্ত তার একটিই স্বপ্ন ছিল সে জীবনে একবার হলেও ক্রাচ ছাড়া যদি হাটতে পারতো কৃত্রিম পায়ের সাহায্য তার সেই স্বপ্ন শুধু স্বপ্নই থেকে গেলো এত বছর, আল্লাহর রহমতে ইউসুফের  সে স্বপ্ন পূরণ হলো। আপনাদের একটু ভালোবাসা আর সাপোর্ট আমার সামনে  যাওয়ার অনুপ্রেরণা
        জয় হোক মানবতার।........
      </p>,
      open: open_2,
      handleClose: handleClose_2,
    },
    {

      img: img_3,
      title: "Abu Hanif- A Engeen Boat Driver, Bashkali, Chattogram",
      description: <p>চট্রগ্রাম  বাশখালীর কিশোর আবু হানিফ। ইঞ্জিন চালিত  বোটে তার ডান পা হারান। তার দু পায়ে দাঁড়ানোর স্বপ্ন বাস্তবায়নে helping hand for chittagonion usa inc.  এর অর্থায়নে কৃত্রিম পা লাগানো হয়। দু পায়ে হেটে গ্রামের হাট বাজারে ঘুরে বেড়াচ্ছে আবু হানিফ তা দেখে আমরা যারা hhcu এর চট্রগ্রামের প্রতিনিধি আছি আমাদেরও অনেক ভালো লাগছে</p>,
      open: open_3,
      handleClose: handleClose_3,
    },
    {

      img: img_4,
      title: "ARMAN UDDIN – 10th Grade Student from  Fatikchari, Chattogram",
      description: <p>১১ বছর ধরে পা নেই চট্টগ্রাম ফটিকছড়ি আজিম নগরের দশম শ্রেণীর ছাত্র আরমান উদ্দিনের । <br></br>মোঃ আরমান উদ্দিন,সে এসেছে ফটিকছড়ির আজিমপুর থেকে,সে বর্তমানে ক্লাস টেন এর ছাত্র তারও স্বপ্ন সে একদিন কৃত্রিম পায়ের সাহায্য স্কুলে যাবে,
        অন্যান্য দশজন স্বাভাবিক ছাত্রের মত পায়ে হেঁটে স্কুল - কলেজে যাবে, বাবা মায়ের কাছে ফিরে যাবে , এটাই তাদের আনন্দ!!!</p>,
      open: open_4,
      handleClose: handleClose_4,
    },

    {

      img: img_5,
      title: "Road Accident Victim Mohammed Alam, Anowara, Chottagram",
      description: <p> Mohammad Alam (Anwara Upazila),  the  boy who are standing on the 'crutch' is  a daily worker. Last year, during Ramadan he falling from a tree and broke his leg.  He doesn't have enough money for treatment.  Now he can only move by crutch. He need a  surgery . The cost will be around tk.30/35 thousand.  Helping Hand Chittagonian inc, USA, response to his application and  give him a grant for treatment.
        <br></br><br></br>ক্রাচে ভর দিয়ে দাঁড়ানো ছেলেটির নাম 'মোহাম্মদ আলম' (বাড়ি-আনোয়ারা উপজেলায় )। দৈনিক ভিত্তিতে ছোট্ট একটি চাকরি করেন। গতবছর ২৪ রোজার দিন গ্রামের বাড়িতে গাছ থেকে পরে একটি পা সম্পূর্ণ ভেংগে যায়। পরবর্তীতে অপারেশন করে রড-স্ক্রু দিয়ে জোড়া দেয়া হয়। তখন ধারদেনা করে চিকিৎসা ব্যয় মিটিয়েছেন। এখন ক্রাচে ভর দিয়ে কোন রকমে চলাফেরা করতে পারেন। ঈদের পরে আবারও অপারেশন করাতে হবে। খরচ পড়বে প্রায় ৩০/৩৫ হাজার টাকা। Helping Hand Chittagonian inc, USA এর পক্ষ থেকে তাঁর আবেদনের প্রেক্ষিতে সংগঠনের USA কমিটির অনুমোদন ক্রমে চিকিৎসা  অনুদান প্রদান করা হয় ।.

      </p>,
      open: open_5,
      handleClose: handleClose_5,
    },
    {

      img: img_6,
      title: "Road Accident Victim Shawkat, Anowara, Chottagram",
      description: <p>  Helping Hand Chattogram inc USA এর পক্ষ থেকে আজ আনোয়ারার প্রত্যন্ত  রায়পুর গ্রামের জনাব শওকতকে চিকিৎসা সহায়তা জন্য চেক তুলে দিচ্ছেন হেল্পিং হ্যান্ড চট্টগ্রাম টিমের সম্মানিত সিনিয়র সদস্য বৃন্দ</p>,
      open: open_6,
      handleClose: handleClose_6,
    },

  ];

  return (

    <div>
      <Header />

      <div className='InnerPageCtn'>
        <PageTitleOne
          Title="Program Details"
          color="#fff"
          backgroundcolor="#33B651"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={

            <div className='SectionContainer'>
              <h1 className='2ndTitle TextCetner'>Artificial Limb Support for extremely poor amputee</h1>
              <div className='ParaSection'>
                <p>Our vision to ensure the inclusion of girls and boys, women and men with disabilities into mainstream society. HHC offer grants in the form of activewear prosthetics artificial limbs to those who  have suffered the loss of a limb . Everyday we work to give people hope and a chance for a better life. society. HHC offer grants in the form of activewear prosthetics artificial limbs to those who  have suffered the loss of a limb . Everyday we work to give people hope and a chance for a better life.<br></br><br></br>
                  <div className='TopBannerCtn'>
                    <img src={img_7} />

                  </div>
                  <br></br>
                  যে সব অসহায় মানুষের  হাত অথবা পা নেই , অথবা দুটাই নেই , অর্থাভাবে অনেক পঙ্গু বা পক্ষঘাত গ্রস্ত  মানুষ বিদেশ থেকে আমদানীকৃত দামী কৃত্রিম পা ও হাত সংযোজন করতে পারেন না,  সে অসহায় মানুষদের পাশে দাঁড়িয়ে কৃত্রিম অঙ্গ  সংযোজন করে নতুন জীবনের স্বাদ দেওয়া।
                  এ ব্যাপারে আমাদের লজিস্টিক সাপোর্ট দিচ্ছেন  "বেওয়ারিশ সেবা ফাউন্ডেশন "
                  একজন পঙ্গু ব্যক্তি হয়তো একটি অঙ্গের কারণে অস্বাভাবিক জীবন যাপন করছে , মাত্র ২০ থেকে ৩০ হাজার টাকা খরচ করে উনাদের  মুখে হাসি ফুটানো সম্ভব
                  জনপ্রতি মাত্র  ৩০০ ইউ এস ডলার করে কয়েকজনকে স্পনসর করতে পারেন ।
                  আমাদের প্রত্যয় যাতে কোন শারীরিক প্রতিবন্ধী ঘরের কোনে বসে না থাকে ৷
                  একজন পঙ্গু ব্যক্তি হয়তো একটি অঙ্গের কারণে অস্বাভাবিক জীবন যাপন করছে , মাত্র ২০ থেকে ৩০ হাজার টাকা খরচ করে তার মুখে হাসি ফুটানো সম্ভব
                  ইতোমধ্যে ৯ জন রোগীকে  নিয়ে প্রাথমিক কাজ শুরু হয়েছে , এর ভেতর একজন মাস্টার্স , একজন দশম শ্রেণীর ছেলে  ও   একজন নবম শ্রেণীর মেয়ে আছে।  ইনশা আল্লাহ  উনাদের সার্জারি হবে পক্ষাঘাতগ্রস্তদের পুনর্বাসন কেন্দ্র একে খান-সিআরপি চট্টগ্রাম কেন্দ্র, কালুরঘাট।  Kalurghat CRP A.K.Khan CRP - Chittagong Center .<br></br><br></br>

                  আমাদের কাজের স্বচ্ছতা জন্য প্রতিটি রোগীর ছবি সহ  আপডেট দেওয়ার চেষ্টা করবো যদি রোগীর কোনো আপত্তি না থাকে।
                  "অসহায় প্রতিবন্ধীদের পাশে দাঁড়িয়ে  আপনার একটু সহানুভূতি মুছে দিতে পারে পঙ্গুত্বের গ্লানি, দিতে পারে নতুন জীবনের স্বাদ।"
                  Limps Support project helps to empower amputee so that they don't have to go through the journey of limp loss.
                  আসুন আমরা আরেকটু মানবিক হই৷ আসুন নিজের কথা ভাবার সাথে সাথে আমাদের পরিবার , সমাজ ও রাষ্ট্রের কথা ভাবি,  বেঁচে থাকার জন্য আজ শুধু এই ভাবনা আর সেই মতো হাত বাড়িয়ে দেওয়াটিই বড় প্রয়োজন। তেমনি কিছু মুষ্টিবদ্ধ হাতের সম্মিলিত প্রচেষ্টা । ক্ষুদ্র ক্ষুদ্র বালুকণা আর বিন্দু বিন্দু জলের সম্মিলনে আমরা চেষ্টা করছি অন্তত কিছু মানুষের কষ্ট লাঘব করতে। আপনাদের ও পাশে চাই। </p>


                <p>HHC offer grants in the form of activewear prosthetics artificial limbs to those who  have suffered the loss of a limb . Everyday we are working hard to give people hope and a chance for a better life. Those helpless people who do not have arms or legs, or both,  many crippled or paralyzed people cannot effort expensive artificial legs and hands imported from abroad, stand beside those helpless people and give them a taste of new life by giving artificial limbs.
                  In this regard, our logistic support is being provided by "Bewarish Seva Foundation" whose leader is CMP Shaukat who is known as Manavik Shaukat.
                  A crippled person may be living an abnormal life because of a limb, it is possible to put a smile on their face by spending only $250 to $300 US Dollar.
                  You can sponsor a few people by paying only 300 US dollars per person.
                  It is our commitment that no physically challenged sit in the room
                  A crippled person may be living an abnormal life because of a limb, it is possible to put a smile on his face by spending only 20 to 30 thousand taka.
                  Initial work has done with 9 patients at AK Khan-CRP Chittagong Center for Paralyzed Rehabilitation, Kalurghat. Kalurghat CRP .
                </p><p>
                  Limps Support project helps to empower amputees so that they don't have to go through the journey of limp loss.
                  Let us be a little more humane Let's think about ourselves as well as about our family, society and state, today only this thought and such a hand is needed to survive. So is the collective effort of some fisted hands. We are trying to reduce the suffering of at least some people by combining small grains of sand and small drops of water. You can donate with Zakat/ charity fund.</p>
              </div>
            </div>
          }
        />


        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer'>
              <h1 className='2ndTitle'> Projects</h1>
              <div className='devider'></div>

              <div>

                <CardFifteen
                  cardFifteenData={usaProjectsUsa}
                  col={4}
                  colSpacing={2}
                  titleColor="#fff"
                  Titlebackground="#0B132B"
                  discriptionColor="#707070"
                  buttonLabel="Read More"
                  //ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#FF6200"
                  BottomLineColor="#FF6200"
                  BottomLine={true}
                  border="border"
                  titleTopLeftcolor="#FF6200"

                  // == Button Style =====//
                  label="Make a donation"
                  link="https://www.google.com/"
                  target="blank"
                  path="/"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#33B651"
                  width="100%"
                  height="50px" slider
                  borderRadius="0px"
                  color="#fff"
                  fontWeight="600"
                  //textAlign="center"
                  Buttonlink="https://give.deenfund.com/hhcusa"
                />

              </div>
            </div>
          }
        />

        <Activities />
        <VideoFromTheField />
        <Projects />
        <CTAOneSection />
      </div>

      {eventDetails.map((item, i) => (



        <ModalFour
          open={item.open}
          handleClose={item.handleClose}
          width="910px"
          height="750px"
          title="Project details"
          img={item.img}
          imgstatus={true}
          // location="New York, United States"
          // Program="Covid-19"
          // Programlink="/programt-details"

          buttonlabel="DONATE NOW"
          buttoncolor="#33B651"
          buttonarrowStatus="true"
          buttonarrowColor="#fff"
          color="#fff"
          buttonlink="https://give.deenfund.com/hhcusa"
          target="_blank"
          buttonwidth="80%"
          buttonheight="40px"
          ButtonRadius="4px"
          textAlign="center"

          // LocationStatus={true}
          // eventTitle="Personal protective equipment (PPE)"
          // eventTitleStatus={true}



          body={
            <div>
              <h2>{item.title}</h2>
              {/* <h3>{item.Location}</h3> */}
              <p>{item.description}</p>
            </div>
          }

        />
      ))}


      <Footer />











    </div>

  );
}


export default ArtificialLimbSupportProgramDetailsPage;