import React, { useState, useEffect } from "react";
import { NavBarEleven, AlertThree } from "master-react-lib";
import "../assets/css/style.css";
import Logo from "../assets/imgs/deenconnect-helping-hand-logo-lg.png";


import MenuIcon from '../assets/imgs/menuIcon.svg';
import CloseIcon from '../assets/imgs/closeIcon.svg';

import facebook from '../assets/imgs/socialicons/FacebookRounded.svg';
import youtube from '../assets/imgs/socialicons/YouTubeRounded.svg';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function Header() {
  // const [tutorialSteps, setTutorialSteps] = useState([]);

  // useEffect(async () => {
  //   const announcements = await getAnnouncements(orgId);

  //   let temp = [];

  //   if (announcements.data.body.data) {
  //     announcements.data.body.data.map((element) => {
  //       let x =
  //         element.announcement?.description &&
  //         JSON.parse(element.announcement.description);
  //       let editor;
  //       if (x) {
  //         editor = { children: [...x] };
  //       }

  //       const description =
  //         element.announcement?.description &&
  //           element.announcement.description?.includes('"type":')
  //           ? serialize(editor)
  //           : element.announcement?.description;

  //       let obj = {
  //         title: element.announcement.title,
  //         path: element.announcement.button.url,
  //         target: "_blank",
  //         description: description,
  //         buttonLabel: element.announcement.button.buttonLabel,
  //         showButton: element.announcement.button.actionButton,
  //       };
  //       if (element.status === "Public") temp.push(obj);
  //     });
  //     setTutorialSteps(temp);
  //   }
  // }, []);



  const click = () => {
    console.log("Back Button Clicked");
  };


  const menuData = [
    {
      label: "HOME",
      to: "/",
      link:"/",
      hasChildren: false,
      
    },

    {
      label: "ABOUT US",
      to: "/",
      //link:"https://www.google.com/",
      hasChildren: true,
      children: [
        { label: "ABOUT HHC", link: "/about-us" },
        { label: "MISSION", link: "/about-us#mission" },
        { label: "VISION", link: "/about-us#vision" },
        { label: "OUR TEAM", link: "/about-us#team" },
        { label: "CONTACT US", link: "/about-us#contact-us" },
      ]

    },

    {
      label: "OUR PROJECTS",
      link:"/our-projects",
      hasChildren: false,

    },

    {
      label: "NEWS",
      link:"/news",
      hasChildren: false,

    },

    {
      label: "PHOTO GALLERY",
      link:"/photo-gallery",
      hasChildren: false,

    },


  ];

  const socialIconData = [
    {
      icon: <img src={facebook} style={{width:"40px"}}/>,
      link: "https://web.facebook.com/hhc.usa",
    },

    {
      icon: <img src={youtube} style={{width:"40px"}}/>,
      link: "https://www.youtube.com/channel/UCqmnHK6rGy8lLA6LPvpqImg",
    },

    // {
    //   icon: <img src={twitter} />,
    //   iconColor: "#03A9F4",
    //   link: "https://twitter.com/",
    // },
  ];

  return (
    <NavBarEleven
      menuData={menuData}
      MenuStatus={true}
      logo={Logo}
      logoLink="/"
      MenuIcon={MenuIcon}
      CloseIcon={CloseIcon}
      background="#fff"
      maxWidth="1175px"
      LogoSize="60px"
      position="fixed"

      borderLine="#E4E8EC"


      socialIconData={socialIconData}

      vrLineBg="#ccc"

      //// Button Style ////
      buttonLebel="DONATE NOW"
      ButtonLink="https://give.deenfund.com/hhcusa"
      target="blank"
      path="/"
      arrowIcon={<ArrowForwardIosRoundedIcon />}
      arrowStatus={true}
      arrowColor="#fff"
      buttoncolor="#FF6200"
      width="auto"
      height="47px"
      border="#ccc 0px solid"
      borderRadius="4px"
      colorlabel="#fff"
      fontWeight="600"
      textAlign="center"
      handIconStatus={true}
      logoTitlesize="30px"
      logoTitleColor="#00924D"
      logoTitle="Helping Hand CHATTOGRAM-USA"

    />
  );
}

export default Header;
