import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Grid from "@material-ui/core/Grid";

import img_1 from "../assets/imgs/bangladesh_pratidin_new-york.webp";
import img_2 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Limbs_&_Disability_Support.png";
import img_3 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Scholarship.png";
import { ButtonOne } from "master-react-lib";
import SubscriptionsRoundedIcon from "@material-ui/icons/SubscriptionsRounded";

import { Section, PageTitleOne, CardSixteen } from "master-react-lib";

import CTAOneSection from "./CTAOneSection";

import CallRoundedIcon from "@material-ui/icons/CallRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import Pattern from "../assets/imgs/./Pattern.svg";
import { getNews } from "./Functions";

import "../assets/css/style.css";

import moment from "moment";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function NewsPage(props) {

  const [totalItem, setTotalItem] = useState([]);
  useEffect(async () => {
    const announcements = await getNews(orgId, "deenweb");
    let temp = [];
    let totalCount = 0;

    if (announcements.data.body.data) {
      setTotalItem(announcements.data.body.data);

      announcements.data.body.data.map((element) => {
        let obj = {
          img: element.article?.imageMetaData?.imageUrl,
          date: moment(element.article.createdAt).format("MMMM D, YYYY"),
          title: element.article.title,
          // link: "/article-details"
          link: "/article-details?q=" + element.article._id,
        };
        totalCount++;

        if (totalCount < 4) {
          element.status === "Public" && temp.push(obj);
        }
      });

      setEventsData(temp);
      // console.log(temp);
    }
  }, []);

  const [eventsData, setEventsData] = useState([]);

  const showAllItem = async () => {
    let temp = [];

    totalItem.map((element) => {
      let obj = {
        img: element.article.imageMetaData?.imageUrl,
        date: moment(element.article.createdAt).format("MMMM D, YYYY"),
        title: element.article.title,
        link: "/article-details?q=" + element.article._id,
      };
      element.status === "Public" && temp.push(obj);
    });

    setEventsData(temp);
  };

  return (
    <div>
      <Header />

      <div className="InnerPageCtn">
        <PageTitleOne
          Title="News"
          color="#fff"
        />

        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="SectionContainer">
              <h1 className="2ndTitle">News</h1>
              <div className="devider"></div>
             
              <Grid container spacing={3}>
                {eventsData.map((item, i) => (
                  <Grid item xs={12}>
                    <CardSixteen
                      image={item.img}
                      date={item.date}
                      title={item.title}
                      buttonText="View Details"
                      link={item.link}
                      readmoreColor="#FF6200"
                    //playicon={<SubscriptionsRoundedIcon />}
                    />

                  </Grid>
                ))}

                <Grid item xs={12}>
                  <ButtonOne
                    label="SEE MORE"
                    link=""
                    onClick={showAllItem}
                    buttoncolor="#F7EBE4"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#FF6200"
                    fontWeight="600"
                    textAlign="center"
                  />
                </Grid>
              </Grid>
            </div>
          }
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="SectionContainer">
              <h1 className="2ndTitle">Contact press team</h1>
              <div className="ContactInfoOne">
                <div class="ContactIfoListContainer">
                  <li class="ContactIfoList">
                    <span>
                      <CallRoundedIcon />{" "}
                    </span>{" "}
                    ‪(347) 470-6377‬
                  </li>
                  <li class="ContactIfoList">
                    <span>
                      <EmailRoundedIcon />{" "}
                    </span>{" "}
                    HelpCtg.usa@gmail.com  </li>
                  <li class="ContactIfoList">
                    <span>
                      <LocationOnRoundedIcon />
                    </span>{" "}
                    73 Mackay PL <br />
                    Brooklyn, NY 11209
                  </li>
                </div>
              </div>
            </div>
          }
        />

        <CTAOneSection />
      </div>
      <Footer />
    </div>
  );
}

export default NewsPage;
