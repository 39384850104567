import React, { useEffect, useState } from "react";
import { SliderSeven } from "master-react-lib";
import Banner from "../assets/imgs/banner.png";
import Container from "@material-ui/core/Container";
import { getSliders } from "./Functions";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import "../assets/css/style.css";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;
function HomeBanner() {
  useEffect(async () => {
    const announcements = await getSliders(orgId);
    let temp = [];

    if (announcements.data.body.data) {
      announcements.data.body.data.map((element) => {
        let obj = {
          title: element.title,
          imgPath: element.imageMetaData.imageUrl,
          discription: JSON.parse(element.description)[0].children[0].text,
          buttonlabel: element.button.buttonLabel,
          link: element.button.buttonLink,
          target: "_blank",
          Buttonstatus:
            element.button.buttonLabel === "" ||
            element.button.buttonLabel === " "
              ? false
              : true,
        };

        element.website.status === "Public" && temp.push(obj);
      });

      setTutorialsteps(temp);
      console.log(temp);
    }
  }, []);

  const [tutorialSteps, setTutorialsteps] = useState([]);

  return (
    <div className="BannerContainer">
      <Container maxWidth="lg">
        <SliderSeven
          tutorialSteps={tutorialSteps}
          Contentbackground="#005A1F"
          // Buttonbackground="#56B149"
          // ButtonRadius="0"
          SliderHieght="496px"
          BannerButton="Read More"
          subtitleColor="#cccc"
          // == Sldier Arrow Style =====//
          ArrowContainerBg="#FF6200"
          arrowBgColor="#fff"
          arrowColor="#FF6200"
          borderRadius="4px"
          leftArrowIcon={<ArrowBackRoundedIcon />}
          RightArrowIcon={<ArrowForwardRoundedIcon />}
          // == Button Style =====//
          arrowIcon={<ArrowForwardRoundedIcon />}
          arrowStatus={true}
          ButtonarrowColor="#fff"
          buttoncolor="#FF6200"
          width="auto"
          height="50px"
          border="#ccc 0px solid"
          color="#fff"
          fontWeight="600"
          textAlign="center"
        />
      </Container>
    </div>
  );
}

export default HomeBanner;
