import React, { createRef, useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Grid from '@material-ui/core/Grid';
import BangladeshFlag from '../assets/imgs/Bangladesh-Flag.jpg';
import UsaIcon from '../assets/imgs/Usa-Icon.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { Section, PageTitleOne, CardFifteen, ModalFour } from "master-react-lib";
import CTAOneSection from './CTAOneSection'
import Pattern from "../assets/imgs/./Pattern.svg";

import img_1 from '../assets/imgs/Ourprojects/deenconnect-hhc-usa-Ashrafi Khatun-14.jpeg';
import img_2 from '../assets/imgs/deenconnect-hhc-usa-project-wheel-chair-amjad-hossain.jpeg';
import img_3 from '../assets/imgs/Ourprojects/deenconnect-hhc-usa-Education.jpeg';
import img_4 from '../assets/imgs/program1/deenconnect-hhc-usa-project-fire-explosion BM-Depot-Sujon-Baharl.jpeg';
import img_5 from '../assets/imgs/BlanketDrive-Anwar.jpeg';
import img_6 from '../assets/imgs/Ourprojects/deenconnect-hhc-usa-Health-Ambulence Service.png';
import img_7 from '../assets/imgs/deenconnect-hhc-usa-Hospitals.png';
import img_8 from '../assets/imgs/deenconnect-hhc-usa-project-AntiBody-Arif 1.jpg';


import '../assets/css/style.css'

function OurProjectPage(props) {

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  // ==== Modals ============//
  const [open_1, setOpen_1] = React.useState(false);
  const handleOpen_1 = () => setOpen_1(true);
  const handleClose_1 = () => setOpen_1(false);


  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => setOpen_2(true);
  const handleClose_2 = () => setOpen_2(false);

  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => setOpen_3(true);
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);

  const [open_5, setOpen_5] = React.useState(false);
  const handleOpen_5 = () => setOpen_5(true);
  const handleClose_5 = () => setOpen_5(false);

  const [open_6, setOpen_6] = React.useState(false);
  const handleOpen_6 = () => setOpen_6(true);
  const handleClose_6 = () => setOpen_6(false);

  const [open_7, setOpen_7] = React.useState(false);
  const handleOpen_7 = () => setOpen_7(true);
  const handleClose_7 = () => setOpen_7(false);

  const [open_8, setOpen_8] = React.useState(false);
  const handleOpen_8 = () => setOpen_8(true);
  const handleClose_8 = () => setOpen_8(false);



  // ==== Modals ============//


  const preProjects = [
    {
      img: img_7,
      titleTop: "",
      flag: BangladeshFlag,
      titleTopLink: "/programt-details",
      title: "COVID 19 : Medical essential emergency support ",
      description: " হেল্পিং হ্যান্ড ফর চিটাগোনিয়ান ইনক , ইউ এস এ এর উদ্দ্যেগে চট্টগ্রামে... ",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_7,
    },
    {
      img: img_8,
      titleTop: "",
      flag: UsaIcon,
      titleTopLink: "/programt-details",
      title: "Covid Anti-Body Testing  ",
      description: "The first free #COVID19 Antibody Testing mobile clinic has been completed to Brooklyn ,NY...",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_8,
    },

  ];


  const currentProjects = [
    {
      img: img_1,
      titleTop: "",
      flag: BangladeshFlag,
      titleTopLink: "/programt-details",
      title: "Artificial Limb Support",
      description: "Our vision to ensure the inclusion of girls and boys, women with disabilities into... ",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_1,
    },

    {
      img: img_2,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Wheelchair for Amputees",
      description: "With an estimated 75 million people around the world todayin need of a wheelchair, Free...  ",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_2,
    },

    {
      img: img_3,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Education Support-Scholarship  Program",
      description: "Throughout the COVID-19 pandemic our wonderful HHC team... ",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_3,
    },

    {
      img: img_4,
      titleTop: "",
      flag: UsaIcon,
      title: "Disaster Management-Emergency Response",
      description: "HHC is a humanitarian organization that actively works to provide relief...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_4,
    },

    {
      img: img_5,
      titleTop: "",
      flag: UsaIcon,
      title: "Winter clothes distributed to the Homeless in Manhattan, NY",
      description: "n Manhattan  , New York,  HHC  distributed hundereds of ...",
      link: "#",
      Donationbtnlink: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
      onClick: handleOpen_5,
    },

    {
      img: img_6,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Mobile Medical Unit & Ambulance Service",
      description: "Mobile medical units bring relief to villagers who are either...",
      link: "#",
      Donationbtnlink: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
      onClick: handleOpen_6,
    },


  ];


  const eventDetails = [
    {
      img: img_1,
      title: "Artifical Limb Support",
      Location: "Location: Bangladesh",
      description: <p>Our vision to ensure the inclusion of girls and boys, women and men with disabilities into mainstream society. HHC offer grants in the form of activewear prosthetics artificial limbs to those who  have suffered the loss of a limb . Everyday we work to give people hope and a chance for a better life. </p>,
      open: open_1,
      handleClose: handleClose_1,
    },
    {

      img: img_2,
      title: "Wheelchair for Amputees",
      description: <p>With an estimated 75 million people around the world today in need of a wheelchair,
        Free Wheelchair Mission continues our mission to provide mobility to people living with disabilities. <br />
        Free Wheelchair Mission continues its focus and commitment on becoming a leading provider of mobility in developing countries,
        but beyond placing recipients in a wheelchair, Free Wheelchair Mission is bringing transformation that opens doors to education,
        employment opportunities, and community that these individuals only dreamed of before receiving the gift of mobility.<br />Wheelchairs
        provide people with disabilities the mobility, inclusion, and care they deserve and need. Our ministry provides quality wheelchairs
        that are safety-tested and tailored to meet the needs and size of each individual, giving people the opportunity to live a fulfilling life!</p>,
      open: open_2,
      handleClose: handleClose_2,
    },
    {

      img: img_3,
      title: "Education Support-Scholarship  Program",
      description: <p>Education is the way to empowerment, and HHC ensures that children and families know the importance of education. Over 169.9 million people in Bangladesh, 31.5% live below poverty line. This is one of the world’s poorest, least developed nations.<br>
      </br> A major reason for this is poverty, children often start work as young as ten-they must start earning their keep. Families are often large to help earn an income and parents struggle to provide basic necessities for them. <br></br><br></br>
        However, while its government provides free primary, secondary, and tertiary education, access to education is hindered by poverty and extreme weather in specific regions of the country.<br></br><br></br>
        ““Educate A Child will reignite the world's commitment to the hardest-to reach children, the forgotten children.  Our mission is to turn attention back to the disadvantaged children of today, who could become confident adults tomorrow, able to fulfil their potential, if we just give them the opportunity.””
      </p>,
      open: open_3,
      handleClose: handleClose_3,
    },
    {

      img: img_4,
      title: "Disaster Management-Emergency Response",
      description: <p><b>HHC is a humanitarian organization that actively works to provide relief by participating in disaster response in disaster areas. provides social services to the underprivileged and those affected by natural disasters.</b><br></br>
        We strive to provide immediate response and aid to disasters stricken areas in Bangladesh and USA. Whenever there is a disaster, be it natural or manmade, there is always a human impact. Helping Hand Chattogram-USA launches emergency appeal in response to a massive fire swept through an inland container facility depot 40 km (25 miles) from the port city of Chittagong, triggering a huge blast and multiple container explosions subsequently, killing at least 50 people and injuring more than 400. <br></br>
        The affected families need urgent help of medicine , treatment , food and other long-term rehabilitation of the affected families is also required. HHC Volunteers physically visited rural areas of victims family and help them directly to their hand.


      </p>,
      open: open_4,
      handleClose: handleClose_4,
    },

    {

      img: img_5,
      title: "Winter clothes distributed to the Homeless in Manhattan, NY",
      description: <p>In Manhattan  , New York,  HHC  distributed hundereds of warm blankets to Poor people who are suffering in a freezing temperatures</p>,

      open: open_5,
      handleClose: handleClose_5,
    },
    {

      img: img_6,
      title: "Mobile medical units bring relief to villagers who are either unable to afford medical care or reside in rural areas that oftentimes are unreachable during natural disasters.  ",
      description: <p>Emergency medical professionals, enabled with ambulatory training, increase their capacity to cope with any given emergency through these services. This also helps provide a means for patients to successfully attend rehabilitation and therapy sessions by securing transportation. HHC have a future plan to provide Mobile Medical Unit & Ambulance Services.</p>,
      open: open_6,
      handleClose: handleClose_6,
    },
    {
      img: img_7,
      title: "Covid-19",
      Location: "Location: Bangladesh",
      description: <p>
        হেল্পিং হ্যান্ড ফর চিটাগোনিয়ান ইনক , ইউ এস এ এর উদ্দ্যেগে চট্টগ্রামে সুরক্ষা সামগ্রী বিতরণ শুরু।
        ১ম ও ২য় দিনে - লাশ দাফনকারী প্রতিষ্টান আল মানহিল ফাউন্ডেশন, গাউসিয়া কমিটি , চট্টগ্রাম মা ও শিশু হাসপাতাল, চট্টগ্রাম প্রেস ক্লাব বিতরণ সম্পন্ন করা হয়।
        <br></br>
        হাসপাতাল, চট্টগ্রাম প্রেস ক্লাব বিতরণ সম্পন্ন করা হয়।
        <br></br>
        দুঃসময়ে সহযোগিতার হাত বাড়িয়ে চট্টগ্রামের পাশে দাঁড়াতে Helping Hand For Chittagonian (HHC) নামে এই শিশু সংগঠনটি মানবিকতার হাত বাড়িয়ে দ্রুত উদ্যোগ নেওয়ার তিন সপ্তাহের ভেতর সম্মুখ যোদ্ধাদের জন্য প্রথম ধাপে বেশ কিছু মেডিকেল সুরক্ষা সামগ্রী উপহার হিসেবে পাঠিয়েছে যা চট্টগ্রামের বড় তিনটি হাসপাতাল, যেমন জেনারেল হাসপাতল , মা ও শিশু হাসপাতাল , আগ্রাবাদ , ও ফৌজদারহাট বক্ষব্যাধি হাপাতাল, ১৫ টি উপজেলা স্বাস্থ্য কমপ্লেক্স , চট্টগ্রাম প্রেস ক্লাব, লাশ দাফনকারী প্রতিষ্টান আল মানহিল ফাউন্ডেশন ও গাউসিয়া কমিটি ও পুলিশ সদস্যদের বিতরণ করা হচ্ছে,যা ভাইরাসের প্রকোপ থেকে কিছুটা হলেও এই যোদ্ধাদের ব্যক্তিগত সুরক্ষা দেবে। এর মধ্যে রয়েছে KN 95 মাস্ক , এক্সামিন গ্লাভস , থের্মোস্ক্যান থার্মোমিটার , ক্লিয়ার চশমা (গগলস) এবং পি পি ই গাউন।

      </p>,
      open: open_7,
      handleClose: handleClose_7,
    },
    {
      img: img_8,
      title: "Covid-19",
      Location: "Location: Bangladesh",
      description: <p>
        হেল্পিং হ্যান্ড ফর চিটাগোনিয়ান ইনক , ইউ এস এ এর উদ্দ্যেগে চট্টগ্রামে সুরক্ষা সামগ্রী বিতরণ শুরু।
        ১ম ও ২য় দিনে - লাশ দাফনকারী প্রতিষ্টান আল মানহিল ফাউন্ডেশন, গাউসিয়া কমিটি , চট্টগ্রাম মা ও শিশু হাসপাতাল, চট্টগ্রাম প্রেস ক্লাব বিতরণ সম্পন্ন করা হয়।
        <br></br>
        হাসপাতাল, চট্টগ্রাম প্রেস ক্লাব বিতরণ সম্পন্ন করা হয়।
        <br></br>
        দুঃসময়ে সহযোগিতার হাত বাড়িয়ে চট্টগ্রামের পাশে দাঁড়াতে Helping Hand For Chittagonian (HHC) নামে এই শিশু সংগঠনটি মানবিকতার হাত বাড়িয়ে দ্রুত উদ্যোগ নেওয়ার তিন সপ্তাহের ভেতর সম্মুখ যোদ্ধাদের জন্য প্রথম ধাপে বেশ কিছু মেডিকেল সুরক্ষা সামগ্রী উপহার হিসেবে পাঠিয়েছে যা চট্টগ্রামের বড় তিনটি হাসপাতাল, যেমন জেনারেল হাসপাতল , মা ও শিশু হাসপাতাল , আগ্রাবাদ , ও ফৌজদারহাট বক্ষব্যাধি হাপাতাল, ১৫ টি উপজেলা স্বাস্থ্য কমপ্লেক্স , চট্টগ্রাম প্রেস ক্লাব, লাশ দাফনকারী প্রতিষ্টান আল মানহিল ফাউন্ডেশন ও গাউসিয়া কমিটি ও পুলিশ সদস্যদের বিতরণ করা হচ্ছে,যা ভাইরাসের প্রকোপ থেকে কিছুটা হলেও এই যোদ্ধাদের ব্যক্তিগত সুরক্ষা দেবে। এর মধ্যে রয়েছে KN 95 মাস্ক , এক্সামিন গ্লাভস , থের্মোস্ক্যান থার্মোমিটার , ক্লিয়ার চশমা (গগলস) এবং পি পি ই গাউন।

      </p>,
      open: open_7,
      handleClose: handleClose_8,
    },


  ];



  const bangladeshRef = createRef();
  const usaRef = createRef();

  useEffect(() => {
    setTimeout(() => {
      if (window.location.href.indexOf("#banglades") > -1) {
        bangladeshRef && bangladeshRef.current.scrollIntoView({ behavior: "smooth" });
      }

      else if (window.location.href.indexOf("#usa") > -1) {
        usaRef && usaRef.current.scrollIntoView({ behavior: "smooth" });
      }


      else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
      }
    }, 1000);

  }, [bangladeshRef, usaRef,]);





  return (

    <div>
      <Header />

      <div className='InnerPageCtn'>
        <PageTitleOne
          Title="Our Projects"
          color="#fff"
        />

        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer' ref={bangladeshRef}>
              <h1 className='2ndTitle'>Our Current Projects</h1>
              <div className='devider'></div>

              <div>

                <CardFifteen
                  cardFifteenData={currentProjects}
                  col={4}
                  colSpacing={2}
                  titleColor="#fff"
                  Titlebackground="#0B132B"
                  discriptionColor="#707070"
                  buttonLabel="Read More"
                  //ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#FF6200"
                  BottomLineColor="#FF6200"
                  BottomLine={true}
                  border="border"
                  titleTopLeftcolor="#FF6200"

                  // == Button Style =====//
                  label="Make a donation"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#33B651"
                  width="100%"
                  height="50px" slider
                  borderRadius="0px"
                  color="#fff"
                  fontWeight="600"
                  //textAlign="center"
                  Buttonlink="https://give.deenfund.com/hhcusa"
                  target="_blank"
                />

              </div>
            </div>
          }
        />





        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer' ref={usaRef}>
              <h1 className='2ndTitle'>Our Previous Projects</h1>
              <div className='devider'></div>

              <div>

                <CardFifteen
                  cardFifteenData={preProjects}
                  col={4}
                  colSpacing={2}
                  titleColor="#fff"
                  Titlebackground="#0B132B"
                  discriptionColor="#707070"
                  buttonLabel="Read More"
                  //ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#FF6200"
                  BottomLineColor="#FF6200"
                  BottomLine={true}
                  border="border"
                  titleTopLeftcolor="#FF6200"

                  // == Button Style =====//
                  label="Make a donation"
                  Buttonlink="https://give.deenfund.com/hhcusa"
                  target="_blank"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#33B651"
                  width="100%"
                  height="50px" slider
                  borderRadius="0px"
                  color="#fff"
                  fontWeight="600"
                //textAlign="center"
                />

              </div>
            </div>
          }
        />
        {eventDetails.map((item, i) => (

          <ModalFour
            open={item.open}
            handleClose={item.handleClose}
            width="100%"
            height="750px"
            title="Project details"
            img={item.img}
            imgstatus={true}
            // location="New York, United States"
            // Program="Covid-19"
            // Programlink="/programt-details"

            buttonlabel="DONATE NOW"
            buttoncolor="#33B651"
            buttonarrowStatus="true"
            buttonarrowColor="#fff"
            color="#fff"
            buttonlink="https://give.deenfund.com/hhcusa"
            target="_blank"
            buttonwidth="80%"
            buttonheight="40px"
            ButtonRadius="4px"
            textAlign="center"

            // LocationStatus={true}
            // eventTitle="Personal protective equipment (PPE)"
            // eventTitleStatus={true}

            body={
              <div>

                <h2>{item.title}</h2>
                {/* <h3>{item.Location}</h3> */}
                <p>{item.description}</p>
              </div>
            }

          />
        ))}

        <CTAOneSection />
      </div>
      <Footer />
    </div>

  );
}


export default OurProjectPage;