import React, { useEffect, useState } from "react";
import Header from './Header'
import Footer from './Footer'

import HomeBanner from './HomeBanner'
import Projects from './Projects'
import AboutUs from './AboutUs'
import PhotoGallery from './PhotoGallery'
import CTAEightSection from './CTAEightSection'
import OurPartners from './OurPartners'
import OurVideos from './OurVideos'
import Blogs from './Blogs'
import { getSliders } from "./Functions";
import '../assets/css/style.css'
const orgId = process.env.REACT_APP_ORGANIZATION_ID;





function HomePage(props) {
  const [show ,setShow]=useState({slider:false})
  useEffect(async () => {
    const announcements = await getSliders(orgId);
    if (announcements.data.body.data) { 
      const check=announcements.data.body.data.filter((e)=>e.website.status==="Public")
      if(check.length>=1)setShow({slider:true})
    }
   }, []);


  return (

    <div>
      <Header />
    {show.slider&&  <HomeBanner />}
      <Projects />
      <AboutUs />
      <PhotoGallery />
      <CTAEightSection />
      <OurPartners />
      <OurVideos />
      <Blogs />
      <Footer /> 
    </div>

  );
}


export default HomePage;