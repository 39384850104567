import React from 'react';
import Header from './Header'
import Footer from './Footer'
import Grid from '@material-ui/core/Grid';
import BangladeshFlag from '../assets/imgs/Bangladesh-Flag.jpg';
import UsaIcon from '../assets/imgs/Usa-Icon.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import img_1 from '../assets/imgs/deenconnect-hhc-usa-project-AntiBody-Arif.jpg';
import img_2 from '../assets/imgs/deenconnect-hhc-usa-project-Hospitals (1).png';
import img_3 from '../assets/imgs/deenconnect-hhc-usa-project-wheel-chair-amjad-hossain.jpeg';
import img_4 from '../assets/imgs/program1/antibody Test.jpeg';
import img_5 from '../assets/imgs/deenconnect-hhc-usa-project-CTG-South.jpeg';
import img_6 from '../assets/imgs/deenconnect-hhc-usa-project-Sandwip-OxyzenConcentrator.jpg';
import img_7 from '../assets/imgs/program1/antibody Test.jpeg';


import { Section, PageTitleOne, CardFifteen, ModalFour } from "master-react-lib";
import CTAOneSection from './CTAOneSection'
import Activities from './Activities'
import VideoFromTheField from './VideoFromTheField'
import Projects from './Projects'

import '../assets/css/style.css'

function MedicalSupportProgramDetailsPage(props) {
  const [open_1, setOpen_1] = React.useState(false);
  const handleOpen_1 = () => setOpen_1(true);
  const handleClose_1 = () => setOpen_1(false);



  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => setOpen_2(true);
  const handleClose_2 = () => setOpen_2(false);



  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => setOpen_3(true);
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);

  const [open_5, setOpen_5] = React.useState(false);
  const handleOpen_5 = () => setOpen_5(true);
  const handleClose_5 = () => setOpen_5(false);


  const [open_6, setOpen_6] = React.useState(false);
  const handleOpen_6 = () => setOpen_6(true);
  const handleClose_6 = () => setOpen_6(false);


  const usaProjectsUsa = [
    {
      img: img_1,
      titleTop: "",
      flag: UsaIcon,
      title: "Covid Anti-Body Testing",
      description: "The first free #COVID19 Antibody Testing mobile clinic  has been completed at  Brooklyn, NY.Organized...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_4,
    },

    {
      img: img_2,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Covid 19: Medical essential emergency support",
      description: "হেল্পিং হ্যান্ড ফর চিটাগোনিয়ান ইনক , ইউ এস এ এর উদ্দ্যেগে চট্টগ্রামে সুরক্ষা সামগ্রী...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_2,
    },

    {
      img: img_3,
      titleTop: "",
      flag: BangladeshFlag,
      title: " Oxygen Concentrator Machine Distributed in all 15 Upazilas",
      description: "করোনা কালে বৃহত্তর চট্টগ্রামবাসীর পাশে দাঁড়িয়েছে যুক্তরাষ্ট্র ভিত্তিক এই...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_3,
    },

    {
      img: img_4,
      titleTop: "",
      flag: BangladeshFlag,
      titleTopLink: "/programt-details",
      title: "Covid19: Oxygen Concentrator Machinein Boalkhalis",
      description: "করোনা কালে বৃহত্তর চট্টগ্রামবাসীর পাশে দাঁড়িয়েছে যুক্তরাষ্ট্র ভিত্তিক এই স্বেচ্ছাসেবী...",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_1,
    },

    {
      img: img_5,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Covid19: Oxygen Concentrator Machinein South Chattogram",
      description: "Oxygen Concentrator Machinein South Chattogram...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_5,
    },

    {
      img: img_6,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Covid19: Oxygen Concentrator Machinein in Sandwip, Chattogram",
      description: "Covid19: Oxygen Concentrator...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_6,
    },


  ];

  const eventDetails = [
    {
      img: img_1,
      title: "Covid Anti-Body Testing",
      Location: "Location: Bangladesh",
      description: <p>The first free #COVID19 Antibody Testing mobile clinic  has been completed at  Brooklyn,
        NY . Organized by FOBANA, HELPING HAND FOR CHITTAGONIAN INC  and Bangladeshi American Police Association (BAPA),
        conducted by Marks Home Care. From 11 am to 5 pm, hundreds of people, both local and foreign, lined up to receive
        this vital health care.<br /><br />

        নিউইয়র্ক  ব্রুকলিনের বাংলাদেশ অধ্যুষিত চার্চ এবং ম্যাকডোনাল্ড এভিনিও'তে ব্যাপক উৎসাহ উদ্দীপনা নিয়ে প্রথমবারের মতো  ফ্রি #COVID_19_Antibody _Testing  সম্পন্ন হয়েছে।
        FOBANA ,  HELPING HAND FOR CHITTAGONIAN এবং Bangladeshi American Police Association (BAPA)  এর সৌজন্যে আয়োজিত, মার্কস হোম কেয়ার দ্বারা পরিচালিত হয় ।সকাল ১১
        টা থেকে বিকাল ৫ টা পর্যন্ত দেশি বিদেশী  কয়েকশো মানুষ লম্বা লাইন দিয়ে এই গুরুত্বপূর্ণ স্বাস্থ্যসেবা গ্রহন করেন। হেল্পিং হ্যান্ড ফর  চিটাগোনিয়ান এর ভলোন্টিয়ার এই মহৎ কাজে সার্বিক ভাবে
        সহযোগিতা করেন।</p>,
      open: open_1,
      handleClose: handleClose_1,
    },
    {

      img: img_2,
      title: "Covid 19: Medical essential emergency support ",
      description: <p>হেল্পিং হ্যান্ড ফর চিটাগোনিয়ান ইনক , ইউ এস এ এর উদ্দ্যেগে চট্টগ্রামে সুরক্ষা সামগ্রী বিতরণ শুরু।
        ১ম ও ২য় দিনে - লাশ দাফনকারী প্রতিষ্টান আল মানহিল ফাউন্ডেশন, গাউসিয়া কমিটি , চট্টগ্রাম মা ও শিশু হাসপাতাল, চট্টগ্রাম প্রেস ক্লাব বিতরণ সম্পন্ন করা হয়।
        <br></br><br></br>
        দুঃসময়ে সহযোগিতার হাত বাড়িয়ে চট্টগ্রামের পাশে দাঁড়াতে Helping Hand For Chittagonian (HHC) নামে এই শিশু সংগঠনটি মানবিকতার হাত বাড়িয়ে দ্রুত উদ্যোগ নেওয়ার তিন সপ্তাহের ভেতর সম্মুখ যোদ্ধাদের জন্য প্রথম ধাপে বেশ কিছু মেডিকেল সুরক্ষা সামগ্রী উপহার হিসেবে পাঠিয়েছে যা চট্টগ্রামের বড় তিনটি হাসপাতাল, যেমন জেনারেল হাসপাতল , মা ও শিশু হাসপাতাল , আগ্রাবাদ , ও ফৌজদারহাট বক্ষব্যাধি হাপাতাল, ১৫ টি উপজেলা স্বাস্থ্য কমপ্লেক্স , চট্টগ্রাম প্রেস ক্লাব, লাশ দাফনকারী প্রতিষ্টান আল মানহিল ফাউন্ডেশন ও গাউসিয়া কমিটি ও পুলিশ সদস্যদের বিতরণ করা হচ্ছে,যা ভাইরাসের প্রকোপ থেকে কিছুটা হলেও এই যোদ্ধাদের ব্যক্তিগত সুরক্ষা দেবে। এর মধ্যে রয়েছে KN 95 মাস্ক , এক্সামিন গ্লাভস , থের্মোস্ক্যান থার্মোমিটার , ক্লিয়ার চশমা (গগলস) এবং পি পি ই গাউন।

      </p>,
      open: open_2,
      handleClose: handleClose_2,
    },
    {

      img: img_3,
      title: "Covid19: Oxygen Concentrator Machine Distributed in all 15 Upazilas",
      description: <p>করোনা কালে বৃহত্তর চট্টগ্রামবাসীর পাশে দাঁড়িয়েছে যুক্তরাষ্ট্র ভিত্তিক এই স্বেচ্ছাসেবী সংগঠন, চট্টগ্রামের ১৫ টি উপজেলা স্বাস্থ কমপ্লেক্স , কক্সবাজার সদর , চট্টগ্রাম জেনারেল হাসপাতাল, মা ও শিশু হাসপাতাল , ফৌজদারহাট (BITID), ফিল্ড হাসপাতাল , প্রেস ক্লাব আল মানহিল ফাউন্ডেশন ও গাউসিয়া কমিটির কাছে প্রাথমিক পর্যায়ে সংগৃহিত অর্থে বিপুল সংখ্যক উন্নতমানের স্বাস্থ সুরক্ষা সামগ্রী প্রদান করেন।
        <br></br>দ্বিতীয় পর্যায়ে চট্টগ্রামের প্রতিটি উপজেলায় খুব দ্রুত মেডিকেল গ্রেডের ৫ এবং ১০ লিটারের অক্সিজেন কন্সেন্ট্রেটর , পালস অক্সিমিটার , অক্সিজেন মাস্ক এবং ক্যানুলা বিতরণ শুরু ।
      </p>,
      open: open_3,
      handleClose: handleClose_3,
    },
    {

      img: img_4,
      title: "Covid19: Oxygen Concentrator Machinein Boalkhalis",
      description: <p>করোনা কালে বৃহত্তর চট্টগ্রামবাসীর পাশে দাঁড়িয়েছে যুক্তরাষ্ট্র ভিত্তিক এই স্বেচ্ছাসেবী সংগঠন, চট্টগ্রামের ১৫ টি উপজেলা স্বাস্থ কমপ্লেক্স , কক্সবাজার সদর , চট্টগ্রাম জেনারেল হাসপাতাল, মা ও শিশু হাসপাতাল , ফৌজদারহাট (BITID), ফিল্ড হাসপাতাল , প্রেস ক্লাব আল মানহিল ফাউন্ডেশন ও গাউসিয়া কমিটির কাছে প্রাথমিক পর্যায়ে সংগৃহিত অর্থে বিপুল সংখ্যক উন্নতমানের স্বাস্থ সুরক্ষা সামগ্রী প্রদান করেন। দ্বিতীয় পর্যায়ে চট্টগ্রামের প্রতিটি উপজেলায় খুব দ্রুত মেডিকেল গ্রেডের ৫ এবং ১০ লিটারের অক্সিজেন কন্সেন্ট্রেটর , পালস অক্সিমিটার , অক্সিজেন মাস্ক এবং ক্যানুলা বিতরণ শুরু ।</p>,
      open: open_4,
      handleClose: handleClose_4,
    },

    {

      img: img_5,
      title: "Covid19: Oxygen Concentrator Machinein South Chattogram ",
      description: <p></p>,
      open: open_5,
      handleClose: handleClose_5,
    },
    {

      img: img_6,
      title: "Covid19: Oxygen Concentrator Machinein in Sandwip, Chattogram",
      description: <p></p>,
      open: open_6,
      handleClose: handleClose_6,
    },

  ];


  return (

    <div>
      <Header />

      <div className='InnerPageCtn'>
        <PageTitleOne
          Title="Program Details"
          color="#fff"
          backgroundcolor="#33B651"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={

            <div className='SectionContainer'>
              <h1 className='2ndTitle TextCetner'>Medical Supports</h1>
              <div className='ParaSection'>
                <p>COVID-19  PANDEMIC EMERGENCY- Our project is dedicated to providing much needed healthcare facilities, medical aid and participate in this global effort to protect the most vulnerable members of our communities both in the United States and Bangladesh.</p>
                <div className='TopBannerCtn'>
                  <img src={img_7} /></div>

                <p>Proper healthcare systems are still unattainable luxuries in some regions of the world. HHC  works to increase access to the best quality health care to those in need in Bangladesh and US . We plan provide free medical camps in the several rural places in Bangladesh. Providing  medical equipments, Oxygen Concentrator,  Protecting gear  to the Hospital , first responders in Bangladesh, it is one of our large project to fight against Corona virus and health care system.
                </p>
                <p>We distributed  Personal Protective Equipment (PPE) to medical service providers including doctors and nurses are at the forefront of this pandemic response and are at high risk of infection. Provided  Oxygen concentrator, PPE including masks, hand sanitizer, gloves, thermometer, Googles, and disinfectant material to health service facilities with the highest need. </p>
              </div>
            </div>
          }
        />


        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer'>
              <h1 className='2ndTitle'> Projects</h1>
              <div className='devider'></div>

              <div>

                <CardFifteen
                  cardFifteenData={usaProjectsUsa}
                  col={4}
                  colSpacing={2}
                  titleColor="#fff"
                  Titlebackground="#0B132B"
                  discriptionColor="#707070"
                  buttonLabel="Read More"
                  //ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#FF6200"
                  BottomLineColor="#FF6200"
                  BottomLine={true}
                  border="border"
                  titleTopLeftcolor="#FF6200"

                  // == Button Style =====//
                  label="Make a donation"
                  link="https://give.deenfund.com/hhcusa"
                  target="blank"
                  path="https://give.deenfund.com/hhcusa"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#33B651"
                  width="100%"
                  height="50px" slider
                  borderRadius="0px"
                  color="#fff"
                  fontWeight="600"
                  //textAlign="center"

                  Buttonlink="https://give.deenfund.com/hhcusa"
                />

              </div>




            </div>
          }
        />





        <Activities />
        <VideoFromTheField />
        <Projects />
        <CTAOneSection />
      </div>
      {eventDetails.map((item, i) => (



        <ModalFour
          open={item.open}
          handleClose={item.handleClose}
          width="910px"
          height="750px"
          title="Project details"
          img={item.img}
          imgstatus={true}
          // location="New York, United States"
          // Program="Covid-19"
          // Programlink="/programt-details"

          buttonlabel="DONATE NOW"
          buttoncolor="#33B651"
          buttonarrowStatus="true"
          buttonarrowColor="#fff"
          color="#fff"
          buttonlink="https://give.deenfund.com/hhcusa"
          target="_blank"
          buttonwidth="80%"
          buttonheight="40px"
          ButtonRadius="4px"
          textAlign="center"

          // LocationStatus={true}
          // eventTitle="Personal protective equipment (PPE)"
          // eventTitleStatus={true}



          body={
            <div>
              <h2>{item.title}</h2>
              {/* <h3>{item.Location}</h3> */}
              <p>{item.description}</p>
            </div>
          }

        />
      ))}

      <Footer />
    </div>

  );
}


export default MedicalSupportProgramDetailsPage;