import React, { useState, useEffect } from "react";
import {
  CarouselOne,
  Section,
  CardEleven,
  CTAOne,
  ButtonOne,
} from "master-react-lib";
import { getSliders, getNews } from "./Functions";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Grid from "@material-ui/core/Grid";
import img_1 from "../assets/imgs/bangladesh_pratidin_new-york.webp";
import img_2 from "../assets/imgs/chittagong-press-club.png";
import img_3 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Scholarship.png";
import img_4 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Volunteer.png";
// import { get } from './Functions';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function Blogs() {
  const [newsdata, setNewsdata] = useState([]); 

  useEffect( async () =>{
    let temp = [];
    let totalCount = 0;

    const news = await getNews(orgId, "deenweb");

    if (news.data.body.data) {
      // setTotalItem(news.data.body.data);

      news.data.body.data.map((element) => {
        if( element.featured == true){          
          let obj = {
            sliderItem: (
              <CardEleven
                img={element.article?.imageMetaData?.imageUrl}
                title={element.article.title}
                link={"/article-details?q=" +  element.article._id}
                titleColor="#fff"
                Titlebackground="#0B132B"
                buttonLabel="Read More"
                ReadmoreIcon={<ArrowForwardRoundedIcon />}
                ReadmoreColor="#33B651"                
              />
            )
          }
          temp.push( obj );
        }else{
          console.log('not')
        }
        
      });
      setNewsdata( temp );
      
    }
  }, [])
  const carouseldata = [
    {
      sliderItem: (
        <CardEleven
          img={img_1}
          title=" নিউইয়র্কের  ব্রুকলিনে করোনা টেস্টের ভ্রাম্যমান ক্যাম্পেও "
          link="/news"
          titleColor="#fff"
          Titlebackground="#0B132B"
          buttonLabel="Read More"
          ReadmoreIcon={<ArrowForwardRoundedIcon />}
          ReadmoreColor="#33B651"
          // BottomLineColor="#FF6200"
        />
      ),
    },
    {
      sliderItem: (
        <CardEleven
          img={img_2}
          title="হেল্পিং হ্যান্ড ফর চিটাগোনিয়ান ইনক ইউএসএ এর উদ্যোগে চট্টগ্রাম প্রেস  "
          link="/news"
          titleColor="#fff"
          Titlebackground="#0B132B"
          buttonLabel="Read More"
          ReadmoreIcon={<ArrowForwardRoundedIcon />}
          ReadmoreColor="#33B651"
          // BottomLineColor="#FF6200"
        />
      ),
    },
  ];

  return (
    <Section
      background="#fff"
      //ContainerWidth=""
      Spacing="40px"
      Container={
        <div className="Blogs">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8}>
              <CarouselOne
                title="News"
                carouseldata={newsdata}
                mobileview={2}
                desktopview={2}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <CTAOne
                    backgroundColor="#FF6200"
                    Title="In Bangladesh, amid ongoing COVID-19 Pandemic threatens to cause a humanitarian crisis."
                    ButtonLebel="MAKE A DONATION"
                    link="https://give.deenfund.com/hhcusa"
                    target="_blank"
                    minHeight="320px"
                    arrowIcon={<ArrowForwardRoundedIcon />}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <ButtonOne
                    label="BECOME A VOLUNTEER"
                    link="/about-us#contact-us"
                    //target="_blank"
                    arrowIcon={<ArrowForwardRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#fff"
                    buttoncolor="#33B651"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#fff"
                    fontWeight="600"
                    //textAlign="center"
                    //disabled="disabled"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default Blogs;
