import React, { useState, useEffect } from "react";
import { Section, ImageGridFour, PageTitleOne } from "master-react-lib";
import { ButtonOne } from "master-react-lib";

import Pattern from "../assets/imgs/./Pattern.svg";
import Header from './Header'
import Footer from './Footer'

import { getPhotos } from "./Functions";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function PhotoGallery() {
  const [showMore, setShowMore] = useState(false);
  useEffect(async () => {
    const announcements = await getPhotos(orgId);
    let temp = [];
    let temp2 = [];

    console.log("photos", announcements?.data?.body?.data);
    if (announcements?.data?.body?.data) {
      announcements?.data?.body?.data?.map((element, i) => {
        let obj = {
          img: element.imageMetaData.imageUrl,
        };

        i < 17 && temp.push(obj);
      });

      setImageGridData(temp);
      console.log(temp);
    }
    if (announcements?.data?.body?.data.length > 16) {
      announcements?.data?.body?.data?.map((element, i) => {
        let obj = {
          img: element.imageMetaData.imageUrl,
        };

        temp2.push(obj);
      });

      setImageGridData2(temp2);
      console.log(temp2);
    }
  }, []);


  const [imgGridData, setImageGridData] = useState([]);
  const [imgGridData2, setImageGridData2] = useState([]);

  const imageClick = (i) => { };

  return (
    <>
      <Header />
      <div className="InnerPageCtn">
        
      <PageTitleOne
        Title="Photo Gallery"
        color="#fff"
      />
      

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="SectionContainer">
              <h1 className="2ndTitle">Photo Gallery</h1>

              <div style={{ backGround: "none" }}>
                {imgGridData.length >= 1 && (
                  <ImageGridFour
                    images={imgGridData.map((e) => e.img)}
                    imageClick={imageClick}
                    imgGridData={showMore ? imgGridData2 : imgGridData}
                  />
                )}
                {imgGridData.length >= 16 && !showMore && (
                  <div style={{ paddingTop: "30px", }}>
                    <ButtonOne
                      label="VIEW ALL PHOTOS"
                      link=""
                      onClick={() => setShowMore(true)}
                      buttoncolor="#F7EBE4"
                      width="100%"
                      height="50px"
                      border="#ccc 0px solid"
                      borderRadius="4px"
                      color="#FF6200"
                      fontWeight="600"
                      textAlign="center"
                    />
                  </div>

                )}
              </div>
            </div>
          }
        />

      </div>
      <Footer />
    </>
  );
}

export default PhotoGallery;
