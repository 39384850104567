import React, { useState, useEffect } from "react";
import {
  CarouselOne,
  Section,
  VideoCardOne,
  VideoPlayerModal,
} from "master-react-lib";

import Icom from "../assets/imgs/PartnerIcom.png";
import SubscriptionsRoundedIcon from "@material-ui/icons/SubscriptionsRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

import img_1 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Covid19.png";
import img_2 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Limbs_&_Disability_Support.png";
import img_3 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Scholarship.png";
import img_4 from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Volunteer.png";
import { getVideos } from "./Functions";
import { makeStyles } from "@material-ui/core/styles";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function OurVideos() {
  const [carouseldata, setCarouseldata] = useState([
    // {
    //   sliderItem: (
    //     <VideoCardOne
    //       img={img_1}
    //       title="Meet muslim genius teaching math in leicester at 14"
    //       link="#"
    //       titleColor="#fff"
    //       Titlebackground="#0B132B"
    //       buttonLabel="View Details"
    //       ReadmoreIcon={<ArrowForwardRoundedIcon />}
    //       ReadmoreColor="#00D4FF"
    //       BottomLineColor="#FF6200"
    //       BottomLine={true}
    //       border="underline"
    //       icon={<SubscriptionsRoundedIcon />}
    //     />
    //   ),
    // },
  ]);
  const videoArray = [];
  const getYouTubeID = (url) => {
    var ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  };

  const [modalVideoid, setmodalVideoId] = useState();

  function openVideoModal(e, videoId) {
    setmodalVideoId(videoId);

    setOpen(true);
  }

  useEffect(async () => {
    const announcements = await getVideos(orgId, "deenweb");
    let temp = [];

    if (announcements.data.body.data) {
      let ordererData = announcements.data.body.data;
      ordererData.sort((a, b) => a.video.order - b.video.order);
      // let arr = ordererData
      // .filter((e) =>  e.featured !== false);
      ordererData.map((element, i) => {
        let videoId = getYouTubeID(element.video.link);
        videoArray.push(videoId);
        let videoThumb = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
        let obj = {
          sliderItem: (
            <div onClick={(e) => openVideoModal(e, videoId)}>
              <VideoCardOne
                img={videoThumb}
                title={element.video.title}
                titleColor="#fff"
                Titlebackground="#0B132B"
                BottomLineColor="#FF6200"
                BottomLine={true}
                border="underline"
                icon={<SubscriptionsRoundedIcon />}
              />
            </div>
          ),
        };

        element.featured && temp.push(obj);
      });

      setCarouseldata(temp);
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    thumb: {
      cursor: "pointer",
      width: "100%",
    },
  }));
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Section
        background="#F6FBFE"
        ContainerWidth=""
        Spacing="40px"
        Container={
          <div className="OurVideo hideArros">
            <CarouselOne
              title="Our Videos"
              carouseldata={carouseldata}
              mobileview={3}
              desktopview={3}
            />
          </div>
        }
      />

      <VideoPlayerModal
        vidoeID={modalVideoid}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}

export default OurVideos;
