import React from 'react';
import { UserAvatarOne, Section, } from "master-react-lib";

import user_1 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Mohammed-N-Anwar-potrait.jpg';
import user_2 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Mir-Qader-Rassel .jpg';
import user_3 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Mohammed-Ibrahim.jpeg';
import user_4 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Abul-Kashem.jpg';
import user_5 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Kamal-Hossain-Mithu.png';
import user_7 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Mohammed-Arif-Chowdhury.png';
import user_6 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Ariful-Islam.jpg';
import user_8 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Ali-Akbar-Bappi.jpg';
import user_9 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Iqbal-Hossain .jpeg';


function BoardofDirectors() {
    const guestCardOneData = [

        {
            img: user_1,
            name: "Mohammed Nurul Anwar ",
            // Designation: "President",
        },
        {
            img: user_2,
            name: "Mir Quader Rasel",
            // Designation: "Executive Director",
        },
        {
            img: user_3,
            name: "Mohammed Ibrahim (Dipu).",
            // Designation: "Finance Director",
        },

        {
            img: user_4,
            name: "Abul Kashem – Businessman",
            // Designation: "Director",
        },

        {
            img: user_5,
            name: "Kamal Hossain Mithu",
            // Designation: "Director",
        },
        {
            img: user_6,
            name: "Md. Ariful Islam",
            // Designation: "Director",
        },
        {
            img: user_7,
            name: "Mohammed Arif Chowdhury",
            // Designation: "Director",
        },

        {
            img: user_8,
            name: "Ali Akbar Bappi ",
            // Designation: "Director",
        },
        {
            img: user_9,
            name: "Iqbal Hossain ",
            // Designation: "Director",
        },

    ];



    return (
        <Section
            background="#33B651"
            //ContainerWidth=""
            Spacing="40px"
            Container={
                <div className='SectionCtn TextWhite'>
                    <h1 className='TextCetner TextWhite'>Board of Directors</h1>
                    <UserAvatarOne
                        guestCardOneData={guestCardOneData}
                        col="3"
                        spacing="2"
                        width=""
                        height="250px"

                    />

                </div>
            }
        />
    );
}


export default BoardofDirectors;