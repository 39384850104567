import React from 'react';
import Header from './Header'
import Footer from './Footer'
import Grid from '@material-ui/core/Grid';
import BangladeshFlag from '../assets/imgs/Bangladesh-Flag.jpg';
import UsaIcon from '../assets/imgs/Usa-Icon.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import img_1 from '../assets/imgs/Ourprojects/deenconnect-hhc-usa-BlanketDrive-Kashem.jpeg';
import img_2 from '../assets/imgs/WinterBlanket-Mcdonald-4.jpg';
import img_3 from '../assets/imgs/program5/deenconnect-hhc-BlanketDrive-Arif.jpeg';
import img_4 from '../assets/imgs/BlanketDrive-Anwar.jpeg';
import img_5 from '../assets/imgs/program1/deenconnect-hhc-usa-project-fire-explosion BM-Depot-Sujon-Baharl.jpeg';
import img_6 from '../assets/imgs/deenconnect-hhc-usa-Health-Ambulence-Service.png';
import img_7 from '../assets/imgs/WinterBlanket-Mcdonald-4.jpg';


import { Section, PageTitleOne, CardFifteen, ModalFour } from "master-react-lib";
import CTAOneSection from './CTAOneSection'
import Activities from './Activities'
import VideoFromTheField from './VideoFromTheField'
import Projects from './Projects'

import '../assets/css/style.css'

function WinterAssistanceAndHungerProgramDetailsPage(props) {

  const [open_1, setOpen_1] = React.useState(false);
  const handleOpen_1 = () => setOpen_1(true);
  const handleClose_1 = () => setOpen_1(false);

  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => setOpen_2(true);
  const handleClose_2 = () => setOpen_2(false);

  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => setOpen_3(true);
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);

  const [open_5, setOpen_5] = React.useState(false);
  const handleOpen_5 = () => setOpen_5(true);
  const handleClose_5 = () => setOpen_5(false);

  const usaProjectsUsa = [
    {
      img: img_1,
      titleTop: "",
      flag: UsaIcon,
      titleTopLink: "/programt-details",
      title: "Winters Blankets  Drive -NYC- HHC distributed ",
      description: "In NewYork city we distributed hundereds of warm blankets to Homeless...",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_1,
    },

    {
      img: img_2,
      titleTop: "",
      flag: UsaIcon,
      title: "Winter Blankets Drive in Brooklyn",
      description: "In Brooklyn, NewYork HHC  distributed hundereds of warm...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_2,
    },

    {
      img: img_3,
      titleTop: "",
      flag: UsaIcon,
      title: "Blankets distributed to  Homeless in  Manhattan, NY",
      description: "In Manhattan, NewYork,HHC US team distributed hundereds...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_3,
    },

    {
      img: img_4,
      titleTop: "",
      flag: UsaIcon,
      title: "Winter clothes distributed to the Homeless in Manhattan, NY",
      description: "In Manhattan, NewYork, HHC distributed hundereds...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_4,
    },

    // {
    //   img: img_5,
    //   titleTop: "",
    //   flag: BangladeshFlag,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: "UPCOMING PROJECT:  Mobile medical units bring relief ",
    //   link: "#",
    //   Donationbtnlink: "https://give.deenfund.com/hhcusa",
    //   onClick: handleOpen_5,
    // },

    // {
    //   img: img_6,
    //   titleTop: "",
    //   flag: BangladeshFlag,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: "UPCOMING PROJECT:  Mobile medical units bring relief to  ",
    //   link: "#",
    //   Donationbtnlink: "https://give.deenfund.com/hhcusa",
    //   onClick: handleOpen_5,
    // },


  ];


  const eventDetails = [
    {
      img: img_1,
      title: "Winters Blankets  Drive -NYC- HHC distributed hundereds of warm blankets to Homeless people who are suffering in a freezing temperatures.",
      Location: "Location: Bangladesh",
      description: <p>In NewYork city we distributed hundereds of warm blankets to Homeless people who are suffering in a freezing temperatures.
        We support helps orphans, widows, the elderly, those living in poverty, refugees, and internally displaced people
        <br></br><br></br>
        ২০২৩ সালের শুভ নববর্ষের প্রথম দিনে শীতার্ত অসহায় ও দুস্থ মানুষের উষ্ণতা দিতে সামাজিক দায়বদ্ধতা থেকে পাশে দাঁড়াল Helping Hand Chattogram- USA ।
        নিউইয়র্ক সিটির ম্যানহাটনে গরীব , গৃহহীন অসহায় মানুষ যারা ফুটপাতে শুয়ে ভিক্ষা করে জীবন কাটায়  তাদের অলিতে গলিতে খুঁজে বের করে অসহায় ও দুস্থদের মাঝে শীতবস্ত্র / কম্বল  বিতরণ করেন মানবতাবাদী প্রবাসী এই সংগঠনটি ।
        <br></br><br></br>
        বাংলাদেশে বিভিন্ন মানবিক কাজের পাশাপাশি যুক্তরাষ্টের এই জনকল্যাণমূলক সেচ্ছাসেবী সংগঠনটি এই কমিউনিটিতে বিভিন্ন সামাজিক কাজে অংশগ্রহণ করে আসছে।

      </p>,
      open: open_1,
      handleClose: handleClose_1,
    },
    {

      img: img_2,
      title: "Winter Blankets Drive in Brooklyn",
      description: <p>In Brooklyn , NewYork HHC  distributed hundereds of warm blankets to Poor people who are suffering in a freezing temperatures.
        <br></br>
        Your donation and support helps orphans, widows, the elderly, those living in poverty, refugees, and internally displaced people
      </p>,
      open: open_2,
      handleClose: handleClose_2,
    },
    {

      img: img_3,
      title: "Blankets distributed to  Homeless in  Manhattan, NY",
      description: <p>In Manhattan  , NewY ork , HHC US team  distributed hundereds of warm blankets to Poor people who are suffering in a freezing temperatures.</p>,
      open: open_3,
      handleClose: handleClose_3,
    },
    {

      img: img_4,
      title: "Winter clothes distributed to the Homeless in Manhattan, NY",
      description: <p>In Manhattan  , New York,  HHC  distributed hundereds of warm blankets to Poor people who are suffering in a freezing temperatures.

      </p>,
      open: open_4,
      handleClose: handleClose_4,
    },

    // {

    //   img: img_5,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: <p>UPCOMING PROJECT:  Mobile medical units bring relief to villagers who are either unable to afford medical care or reside in rural areas that oftentimes are unreachable during natural disasters. Emergency medical professionals, enabled with ambulatory training, increase their capacity to cope with any given emergency through these services. This also helps provide a means for patients to successfully attend rehabilitation and therapy sessions by securing transportation. HHC have a future plan to provide Mobile Medical Unit & Ambulance Services,</p>,
    //   open: open_5,
    //   handleClose: handleClose_5,
    // },
    // {

    //   img: img_6,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: <p>UPCOMING PROJECT:  Mobile medical units bring relief to villagers who are either unable to afford medical care or reside in rural areas that oftentimes are unreachable during natural disasters. Emergency medical professionals, enabled with ambulatory training, increase their capacity to cope with any given emergency through these services. This also helps provide a means for patients to successfully attend rehabilitation and therapy sessions by securing transportation. HHC have a future plan to provide Mobile Medical Unit & Ambulance Services,</p>,
    //   open: open_5,
    //   handleClose: handleClose_5,
    // },

  ];





  return (

    <div>
      <Header />

      <div className='InnerPageCtn'>
        <PageTitleOne
          Title="Program Details"
          color="#fff"
          backgroundcolor="#33B651"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={

            <div className='SectionContainer'>
              <h1 className='2ndTitle TextCetner'>Homeless Assistance and Hunger Prevention.</h1>
              <div className='ParaSection'>
                <p>Help Vulnerable Families Survive in this  harsh winter weather.  With your support we can provide warmth and protection to families who need your help.
                  Frigid temperatures are devastating for those who are unsheltered or living in makeshift homes. With no way to keep warm, this time of year can even be deadly. Together, we can ensure their lives are full of warmth. When you send a winter package to a family in need, you are providing relief and saving lives.
                </p>
                <div className='TopBannerCtn'>
                  <img src={img_7} />

                </div>

                <p>Families need these items to ensure they survive the winter. Your support helps orphans, widows, the elderly, those living in poverty, refugees, and internally displaced people. Help save a life.
                  In NewYork city we distributed hundereds of warm blankets to Homeless people who are suffering in a freezing temperatures.
                  Your support helps orphans, widows, the elderly, those living in poverty, refugees, and internally displaced people
                  There are lots of ways you can help, you can volunteer to feed those in need, or donate funds to provide winter coats, blankets  or sponsor a winter package today!<br></br>
                  With your support we can provide warmth and protection to families who need your help.

                </p>
              </div>
            </div>
          }
        />


        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer'>
              <h1 className='2ndTitle'>Projects</h1>
              <div className='devider'></div>

              <div>

                <CardFifteen
                  cardFifteenData={usaProjectsUsa}
                  col={4}
                  colSpacing={2}
                  titleColor="#fff"
                  Titlebackground="#0B132B"
                  discriptionColor="#707070"
                  buttonLabel="Read More"
                  //ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#FF6200"
                  BottomLineColor="#FF6200"
                  BottomLine={true}
                  border="border"
                  titleTopLeftcolor="#FF6200"

                  // == Button Style =====//
                  label="Make a donation"
                  link="https://www.google.com/"
                  target="blank"
                  path="/"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#33B651"
                  width="100%"
                  height="50px" slider
                  borderRadius="0px"
                  color="#fff"
                  fontWeight="600"
                  //textAlign="center"
                  Buttonlink="https://give.deenfund.com/hhcusa"
                />

              </div>




            </div>
          }
        />





        <Activities />
        <VideoFromTheField />
        <Projects />
        <CTAOneSection />
      </div>
      {eventDetails.map((item, i) => (



        <ModalFour
          open={item.open}
          handleClose={item.handleClose}
          width="910px"
          height="750px"
          title="Project details"
          img={item.img}
          imgstatus={true}
          // location="New York, United States"
          // Program="Covid-19"
          // Programlink="/programt-details"

          buttonlabel="DONATE NOW"
          buttoncolor="#33B651"
          buttonarrowStatus="true"
          buttonarrowColor="#fff"
          color="#fff"
          buttonlink="https://give.deenfund.com/hhcusa"
          target="_blank"
          buttonwidth="80%"
          buttonheight="40px"
          ButtonRadius="4px"
          textAlign="center"

          // LocationStatus={true}
          // eventTitle="Personal protective equipment (PPE)"
          // eventTitleStatus={true}



          body={
            <div>
              <h2>{item.title}</h2>
              {/* <h3>{item.Location}</h3> */}
              <p>{item.description}</p>
            </div>
          }

        />
      ))}
      <Footer />
    </div>

  );
}


export default WinterAssistanceAndHungerProgramDetailsPage;