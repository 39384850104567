
import React from 'react'
import { FooterThree, FooterBottomOne } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import tickIcon from '../assets/imgs/checkIcon.svg'

import Logo from '../assets/imgs/deenconnect-helping-hand-logo-med.png';
import footerLogo from '../assets/imgs/deenconnect-helping-hand-logo-med.png';
import VideoCam from '../assets/imgs/videocam.svg'
import location from '../assets/imgs/location.svg'
import call from '../assets/imgs/call.svg'
import email from '../assets/imgs/email.svg'
import Locationicon from '../assets/imgs/Locationicon.svg'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import WhatsApp from "../assets/imgs/socialicons/WhatsappRounded.svg";



function Footer() {

    const FooterThreeColOne = [
        { description: " 501 (c)(3) Not for Profit organization.", },
    ];
    const FooterThreeSocial = [
        { icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-facebook-icon.webp", link: "https://web.facebook.com/hhc.usa" },
        // { icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-twitter-icon.webp", link: "" },
        { icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-youtube-icon.webp", link: "https://www.youtube.com/channel/UCqmnHK6rGy8lLA6LPvpqImg" },
        // { icon: WhatsApp, link: "" },
    ];



    const FooterThreeContactinfo = [
        { TextOne: " ‪(718) 306-7288‬", TextTwo: "", icon: call },
        { TextOne: "HelpCtg.usa@gmail.com", icon: email },
        { TextOne: "73 Mackay PL", TextTwo: "Brooklyn, NY 11209", icon: location },
    ];

    const footerThreelinks = [
        {
            label: "Home",
            link: "/",
        },
        {
            label: "About Us",
            link: "/about-us",
        },
        {
            label: "Contact Us",
            link: "/about-us#contact-us",
        },
        {
            label: "Volunteer",
            link: "/about-us#contact-us",
        },
        {
            label: "Our Programs",
            link: "/",
        },


    ];


    return (

        <>
            <div className="ExpansionFooterContainer">

                <FooterThree
                    background="#0B132B"
                    color="#FFFFFF"
                    FooterThreeColOne={FooterThreeColOne}
                    FooterThreeSocial={FooterThreeSocial}
                    FooterThreeContactinfo={FooterThreeContactinfo}
                    footerThreelinks={footerThreelinks}
                    ContainerMaxWidth="1175px"

                    TitleOne="CONTACT"
                    TitleTwo="IMPORTANT LINKS"


                    logo={Logo}
                    ColTitleOne="Helping Hand For Chittagonian"

                    dividerLine="#676C7B"
                    Bottomlogo={footerLogo}
                    BottomTitle="Helping Hand For Chittagonian"

                    TosLink="/Terms-Policies"
                    ppLink="/Terms-Policies"

                    //// Button Style ////
                    label="FOLLOW US ON FACEBOOK"
                    link="https://web.facebook.com/hhc.usa"
                    target="blank"
                    path="/"
                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#fff"
                    buttoncolor="#4267B2"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="0px"
                    Color="#fff"
                    fontWeight="600"
                    //textAlign="center"
                    //disabled="disabled"
                    FooterRowTwo={true}
                    CardLogoSection={false}
                    CardSpacing="0px"
                    AllRightReservedText="© 2023 HELPING HAND FOR CHITTAGONIAN. ALL RIGHT RESERVED"
                />




            </div>

            <FooterBottomOne
                bordercolor="#FF6200"
                Container="1170px"

                TermsLink="https://deenconnect.io/terms-policies"
                PrivacyLink="https://deenconnect.io/terms-policies"

            />
        </>

    );
}










export default Footer;