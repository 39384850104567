import React, { useState, useEffect } from "react";
import { Section, ImageGridFour } from "master-react-lib";
import { ButtonOne } from "master-react-lib";


import img_1 from "../assets/imgs/img_1.png";
import img_2 from "../assets/imgs/img_2.png";
import img_3 from "../assets/imgs/img_3.png";
import img_4 from "../assets/imgs/img_4.png";
import img_5 from "../assets/imgs/img_5.png";

import { getPhotos } from "./Functions";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function PhotoGallery() {
  const [showMore, setShowMore] = useState(false);
  useEffect(async () => {
    const announcements = await getPhotos(orgId);
    let temp = [];
    let temp2 = [];

    console.log("photos", announcements?.data?.body?.data);
    if (announcements?.data?.body?.data) {
      announcements?.data?.body?.data?.map((element, i) => {
        let obj = {
          img: element.imageMetaData.imageUrl,
        };

        i < 10 && temp.push(obj);
      });

      setImageGridData(temp);
      console.log(temp);
    }
    if (announcements?.data?.body?.data.length > 10) {
      announcements?.data?.body?.data?.map((element, i) => {
        let obj = {
          img: element.imageMetaData.imageUrl,
        };

        temp2.push(obj);
      });

      setImageGridData2(temp2);
      console.log(temp2);
    }
  }, []);


  const [imgGridData, setImageGridData] = useState([]);
  const [imgGridData2, setImageGridData2] = useState([]);

  const imageClick = (i) => {};

  return (
    <>

      <div className="InnerPageCtn">

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="SectionContainer">
              <h1 className="2ndTitle">Photo Gallery</h1>

              <div style={{ backGround: "none" }}>
                {imgGridData.length >= 1 && (
                  <ImageGridFour
                    images={imgGridData.map((e) => e.img)}
                    imageClick={imageClick}
                    imgGridData={showMore ? imgGridData2 : imgGridData}
                  />
                )}
                {imgGridData.length >= 10 && !showMore && (
                  <div style={{paddingTop: "30px",}}>
                  <ButtonOne
                    label="VIEW ALL PHOTOS"
                    link="/photo-gallery"
                    //onClick={() => setShowMore(true)}
                    buttoncolor="#F7EBE4"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#FF6200"
                    fontWeight="600"
                    textAlign="center"
                  />
                  </div>
                  
                )}
              </div>
            </div>
          }
        />

      </div>
    </>
  );
}

export default PhotoGallery;
