import React from 'react';
import Header from './Header'
import Footer from './Footer'
import Grid from '@material-ui/core/Grid';
import BangladeshFlag from '../assets/imgs/Bangladesh-Flag.jpg';
import UsaIcon from '../assets/imgs/Usa-Icon.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import img_1 from '../assets/imgs/deenconnect-hhc-usa-project-wheel-chair-amjad-hossain-1.jpeg';
import img_2 from '../assets/imgs/Ourprojects/deenconnect-hhc-usa-WheelChair2023.jpeg';
import img_3 from '../assets/imgs/deenconnect-hhc-usa-project-wheel-chair-amjad-hossain.jpeg';
import img_4 from '../assets/imgs/program1/antibody Test.jpeg';
import img_5 from '../assets/imgs/program1/deenconnect-hhc-usa-project-fire-explosion BM-Depot-Sujon-Baharl.jpeg';
import img_6 from '../assets/imgs/deenconnect-hhc-usa-Health-Ambulence-Service.png';
import img_7 from '../assets/imgs/Ourprojects/deenconnect-hhc-usa-WheelChair2023.jpeg';


import { Section, PageTitleOne, CardFifteen, ModalFour } from "master-react-lib";
import CTAOneSection from './CTAOneSection'
import Activities from './Activities'
import VideoFromTheField from './VideoFromTheField'
import Projects from './Projects'

import '../assets/css/style.css'

function WheelChairforHandicappedProgramDetailsPage(props) {
  const [open_1, setOpen_1] = React.useState(false);
  const handleOpen_1 = () => setOpen_1(true);
  const handleClose_1 = () => setOpen_1(false);


  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => setOpen_2(true);
  const handleClose_2 = () => setOpen_2(false);



  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => setOpen_3(true);
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);

  const [open_5, setOpen_5] = React.useState(false);
  const handleOpen_5 = () => setOpen_5(true);
  const handleClose_5 = () => setOpen_5(false);


  const usaProjectsUsa = [
    {
      img: img_1,
      titleTop: "",
      flag: BangladeshFlag,
      titleTopLink: "/programt-details",
      title: "HHC provide a free wheelchair to a  22 years old handicapped ",
      description: "HHC provide a free wheelchair to a  22 years old handicapped  Amzad...",
      link: "/limb-support-project-details",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_1,
    },

    {
      img: img_2,
      titleTop: "",
      flag: BangladeshFlag,
      title: "Kabir Hossian-Comilla",
      description: "মানুষ মানুষের জন্য। Helping Hand Inc USA Chattogram এর পক্ষ থেকে গাড়িএক্সিডেন্টে আহত চান্দিনার( কুমিল্লা)...",
      link: "#",
      Donationbtnlink: "https://give.deenfund.com/hhcusa",
      onClick: handleOpen_2,
    },

    // {
    //   img: img_3,
    //   titleTop: "",
    //   flag: BangladeshFlag,
    //   title: "Wheelchair for Amputees",
    //   description: "With an estimated 75 million people around the world todayin need of a wheelchair, Free  ",
    //   link: "#",
    //   Donationbtnlink: "https://give.deenfund.com/hhcusa",
    //   onClick: handleOpen_3,
    // },

    // {
    //   img: img_4,
    //   titleTop: "",
    //   flag: UsaIcon,
    //   title: "Covid Anti-Body Testing",
    //   description: "The first free #COVID19 Antibody Testing mobile clinic  has been completed at  Brooklyn, NY . Organized by ",
    //   link: "#",
    //   Donationbtnlink: "https://give.deenfund.com/hhcusa",
    //   onClick: handleOpen_4,
    // },

    // {
    //   img: img_5,
    //   titleTop: "",
    //   flag: BangladeshFlag,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: "UPCOMING PROJECT:  Mobile medical units bring relief ",
    //   link: "#",
    //   Donationbtnlink: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
    //   onClick: handleOpen_5,
    // },

    // {
    //   img: img_6,
    //   titleTop: "",
    //   flag: BangladeshFlag,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: "UPCOMING PROJECT:  Mobile medical units bring relief to  ",
    //   link: "#",
    //   Donationbtnlink: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
    //   onClick: handleOpen_5,
    // },


  ];


  const eventDetails = [
    {
      img: img_1,
      title: "HHC provide a free wheelchair to a  22 years old handicapped  Amzad Hossain , He lives in  Barbakunda, Sitakunda, Chattogram . ",
      Location: "Location: Bangladesh",
      description: <p>HHC provide a free wheelchair to a  22 years old handicapped  Amzad Hossain , He lives in  Barbakunda, Sitakunda, Chattogram .
        <br></br><br></br>শারীরিক প্রতিবন্ধী মো. আমজাদ হোসেন (২২), (পিতা -নুর আলম, মাতা-শহরবানু, মধ্যম মাহমুদাবাদ, বাড়বকুণ্ড, সীতাকুণ্ড, চট্টগ্রাম)-কে একটি হুইল চেয়ার  প্রদান .হুইলচেয়ার পেয়ে আমজাদ ও তার পিতামাতার আনন্দ আমাদের বিমোহিত করেছে।
      </p>,
      open: open_1,
      handleClose: handleClose_1,
    },
    {

      img: img_2,
      title: "Kabir Hossian-Comilla",
      description: <p>
        মানুষ মানুষের জন্য। Helping Hand Inc USA Chattogram এর পক্ষ থেকে গাড়ি এক্সিডেন্টে আহত চান্দিনার( কুমিল্লা)  কবির হোসেনকে হুইল চেয়ার প্রদান করা হলো।  কবির হোসেন এতদিন ভিক্ষা করতেন। হুইলচেয়ার প্রদান অনুষ্ঠানে কবির হোসেনকে ভিক্ষাবৃত্তি ছেড়ে  ক্ষুদ্র ব্যবসা শুরু করার জন্য Helping Hand Chattogram -পূঁজি হিসেবে আর্থিক সহায়তা প্রদান করেন।  আশাকরি কবির হোসেন ভিক্ষাবৃত্তি ছেড়ে ক্ষুদ্র ব্যবসা শুরু করতে পারবেন।
      </p>,
      open: open_2,
      handleClose: handleClose_2,
    },
    // {

    //   img: img_3,
    //   title: "Wheelchair for Amputees",
    //   description: <p>With an estimated 75 million people around the world today in need of a wheelchair,
    //     Free Wheelchair Mission continues our mission to provide mobility to people living with disabilities. <br />
    //     Free Wheelchair Mission continues its focus and commitment on becoming a leading provider of mobility in developing countries,
    //     but beyond placing recipients in a wheelchair, Free Wheelchair Mission is bringing transformation that opens doors to education,
    //     employment opportunities, and community that these individuals only dreamed of before receiving the gift of mobility. <br /><br />Wheelchairs
    //     provide people with disabilities the mobility, inclusion, and care they deserve and need. Our ministry provides quality wheelchairs
    //     that are safety-tested and tailored to meet the needs and size of each individual, giving people the opportunity to live a fulfilling life!</p>,
    //   open: open_3,
    //   handleClose: handleClose_3,
    // },
    // {

    //   img: img_4,
    //   title: "Covid Anti-Body Testing",
    //   description: <p>The first free #COVID19 Antibody Testing mobile clinic  has been completed at  Brooklyn,
    //     NY . Organized by FOBANA, HELPING HAND FOR CHITTAGONIAN INC  and Bangladeshi American Police Association (BAPA),
    //     conducted by Marks Home Care. From 11 am to 5 pm, hundreds of people, both local and foreign, lined up to receive
    //     this vital health care.<br /><br />

    //     নিউইয়র্ক  ব্রুকলিনের বাংলাদেশ অধ্যুষিত চার্চ এবং ম্যাকডোনাল্ড এভিনিও'তে ব্যাপক উৎসাহ উদ্দীপনা নিয়ে প্রথমবারের মতো  ফ্রি #COVID_19_Antibody _Testing  সম্পন্ন হয়েছে।
    //     FOBANA ,  HELPING HAND FOR CHITTAGONIAN এবং Bangladeshi American Police Association (BAPA)  এর সৌজন্যে আয়োজিত, মার্কস হোম কেয়ার দ্বারা পরিচালিত হয় ।সকাল ১১
    //     টা থেকে বিকাল ৫ টা পর্যন্ত দেশি বিদেশী  কয়েকশো মানুষ লম্বা লাইন দিয়ে এই গুরুত্বপূর্ণ স্বাস্থ্যসেবা গ্রহন করেন। হেল্পিং হ্যান্ড ফর  চিটাগোনিয়ান এর ভলোন্টিয়ার এই মহৎ কাজে সার্বিক ভাবে
    //     সহযোগিতা করেন।</p>,
    //   open: open_4,
    //   handleClose: handleClose_4,
    // },

    // {

    //   img: img_5,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: <p>UPCOMING PROJECT:  Mobile medical units bring relief to villagers who are either unable to afford medical care or reside in rural areas that oftentimes are unreachable during natural disasters. Emergency medical professionals, enabled with ambulatory training, increase their capacity to cope with any given emergency through these services. This also helps provide a means for patients to successfully attend rehabilitation and therapy sessions by securing transportation. HHC have a future plan to provide Mobile Medical Unit & Ambulance Services,</p>,
    //   open: open_5,
    //   handleClose: handleClose_5,
    // },
    // {

    //   img: img_6,
    //   title: "Mobile Medical Unit & Ambulance Service",
    //   description: <p>UPCOMING PROJECT:  Mobile medical units bring relief to villagers who are either unable to afford medical care or reside in rural areas that oftentimes are unreachable during natural disasters. Emergency medical professionals, enabled with ambulatory training, increase their capacity to cope with any given emergency through these services. This also helps provide a means for patients to successfully attend rehabilitation and therapy sessions by securing transportation. HHC have a future plan to provide Mobile Medical Unit & Ambulance Services,</p>,
    //   open: open_5,
    //   handleClose: handleClose_5,
    // },

  ];

  return (

    <div>
      <Header />

      <div className='InnerPageCtn'>
        <PageTitleOne
          Title="Program Details"
          color="#fff"
          backgroundcolor="#33B651"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={

            <div className='SectionContainer'>
              <h1 className='2ndTitle TextCetner'>Wheelchair for Handicapped</h1>
              <div className='ParaSection'>
                <p><b>Wheelchair for Handicapped - Helping Those In Need .</b> Wheelchairs provide people with disabilities the mobility, inclusion, and care they deserve and need. We're giving people the opportunity to live a fulfilling life!
                </p>
                <div className='TopBannerCtn'>
                  <img src={img_7} />
                </div>


                <p>With an estimated 75 million people around the world today in need of a wheelchair. Wheelchairs provide people with disabilities the mobility, inclusion, and care they deserve and need. Wheelchair Project is bringing transformation that opens doors to education, employment opportunities, and community that these individuals only dreamed of before receiving the gift of mobility.  Our Wheelchair Aid program maximizes the impact of each gift you make – because we know that small change makes a big difference.
                  <b>YOU CAN SPONSOR ONE WHEELCHAIR  BY DONATE  $100 </b>

                </p>
              </div>
            </div>
          }
        />


        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer'>
              <h1 className='2ndTitle'> Projects</h1>
              <div className='devider'></div>

              <div>

                <CardFifteen
                  cardFifteenData={usaProjectsUsa}
                  col={4}
                  colSpacing={2}
                  titleColor="#fff"
                  Titlebackground="#0B132B"
                  discriptionColor="#707070"
                  buttonLabel="Read More"
                  //ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#FF6200"
                  BottomLineColor="#FF6200"
                  BottomLine={true}
                  border="border"
                  titleTopLeftcolor="#FF6200"

                  // == Button Style =====//
                  label="Make a donation"
                  link="https://www.google.com/"
                  target="blank"
                  path="/"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#33B651"
                  width="100%"
                  height="50px" slider
                  borderRadius="0px"
                  color="#fff"
                  fontWeight="600"
                  //textAlign="center"
                  Buttonlink="https://give.deenfund.com/hhcusa"
                />

              </div>

            </div>
          }
        />

        <Activities />
        <VideoFromTheField />
        <Projects />
        <CTAOneSection />
      </div>

      {eventDetails.map((item, i) => (



        <ModalFour
          open={item.open}
          handleClose={item.handleClose}
          width="910px"
          height="750px"
          title="Project details"
          img={item.img}
          imgstatus={true}
          // location="New York, United States"
          // Program="Covid-19"
          // Programlink="/programt-details"

          buttonlabel="DONATE NOW"
          buttoncolor="#33B651"
          buttonarrowStatus="true"
          buttonarrowColor="#fff"
          color="#fff"
          buttonlink="https://give.deenfund.com/hhcusa"
          target="_blank"
          buttonwidth="80%"
          buttonheight="40px"
          ButtonRadius="4px"
          textAlign="center"

          // LocationStatus={true}
          // eventTitle="Personal protective equipment (PPE)"
          // eventTitleStatus={true}



          body={
            <div>
              <h2>{item.title}</h2>
              {/* <h3>{item.Location}</h3> */}
              <p>{item.description}</p>
            </div>
          }

        />
      ))}











      <Footer />
    </div>

  );
}


export default WheelChairforHandicappedProgramDetailsPage;