import React from 'react';
import { UserAvatarOne, Section, } from "master-react-lib";

import user_1 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Musa-Khan.jpg';
import user_2 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Younus-Ghani-Chowdhury.jpeg';
import user_3 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Delware.jpeg';
import user_4 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Abdul-Matin.jpeg';
import user_5 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Mostafa-Monowar.png';
import user_6 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Sadik.jpg';
import user_7 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Kamal.jpg';
import user_8 from '../assets/imgs/OurTeam/deenconnect-hhc-usa-Mohsin-Ul-Quader.jpg';


function ExecutiveTeam() {
    const guestCardOneData = [

        {
            img: user_1,
            name: "M Musa khan",
            Designation: " A columnist, Writer & a Social Activist",
        },
        {
            img: user_2,
            name: "Younus Gani Chowdhury",
            Designation: " A Politician, Ex Board Member CDA & Businessman",
        },
        {
            img: user_3,
            name: "Delwar Hossain",
            Designation: " Freedom Fighter, Businessman",
        },

        {
            img: user_4,
            name: "Md Abdul Matin Chowdhury  ",
            Designation: "Police Inspector",
        },

        {
            img: user_5,
            name: "Mohammed Mostafa Monowar",
            Designation: "Community Activist, Business Owner",
        },
        {
            img: user_6,
            name: "A.Z.M Sadek M",
            Designation: "Manager- Human Resource",
        },
        {
            img: user_7,
            name: "Kamal Uddin  ",
            Designation: "Ex Secretary, Chittagong Association of North America",
        },

        {
            img: user_8,
            name: "Mohsin Ul Quader   ",
            Designation: " FAVP, Eastern Bank Ltd, CTG.",
        },  

    ];



    return (
        <Section
            background="#F6FBFE"
            //ContainerWidth=""
            Spacing="40px"
            Container={
                <div className='SectionCtn'>
                    <h1 className='TextCetner TextDard'>Executive Team - Bangladesh</h1>
                    <UserAvatarOne
                        guestCardOneData={guestCardOneData}
                        col="3"
                        spacing="2"
                        width=""
                        height="250px"

                    />

                </div>
            }
        />
    );
}


export default ExecutiveTeam;