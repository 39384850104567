import React, { useState } from "react";
import {
  ContactInfoOne,
  ContactUsThree,
  Section,
  ContactUsOne,
} from "master-react-lib";
import Grid from "@material-ui/core/Grid";

import FacebookIcon from "../assets/imgs/socialicons/FacebookRounded.svg";
import YouTubeIcon from "../assets/imgs/socialicons/YouTubeRounded.svg";
import TwitterIcon from "../assets/imgs/socialicons/TwitterRounded.svg";
import WhatsAppIcon from "../assets/imgs/socialicons/WhatsappRounded.svg";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";

import officeImg from "../assets/imgs/DEENCONNECT_Helping_Hand_for_Chittagonian_HHC_Covid19.png";
import SuccessAlert from "./SuccessAlert";
import axios from "axios";

function ContactUs() {
  const [open, setopen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");

  const onContactFormSubmit = (contactFormData) => {
    console.log("hi");
    console.log("contactFormData", contactFormData);

    if (true) {
      let {
        firstName,
        lastName,
        message,
        select: inquiryType,
        email,
        phone,
      } = contactFormData;
      if (firstName && lastName && message && inquiryType && email && phone) {
        console.log("API CALLED....");
        axios
          .post(process.env.REACT_APP_BASEURL_DEV_API + "/contact", {
            message,
            inquiryType,
            email,
            phone,
            name: {
              firstName,
              lastName,
            },
            orgId: process.env.REACT_APP_ORGANIZATION_ID,
            stream: process.env.REACT_APP_CONTACT_STREAM,
          })
          .then((res) => {
            setopen(true, email);
            setCurrentEmail(email);
            localStorage.setItem("contactMail", email);
            console.log("contact ", res);
          })
          .catch((err) => console.error(err));
      }
    }
  };

  const contactDetails = [
    { icon: <CallRoundedIcon />, details: " ‪ (347) 470-6377" },
    { icon: <EmailRoundedIcon />, details: "HelpCtg.usa@gmail.com" },
    {
      icon: <LocationOnRoundedIcon />,
      details: (
        <div>
        73 Mackay PL  <br /> Brooklyn, NY 11209
        </div>
      ),
    },
  ];

  const socialLinks = [
    {
      icon: <img src={FacebookIcon} />,
      link: "https://web.facebook.com/hhc.usa",
    },
    {
      icon: <img src={YouTubeIcon} />,
      link: "https://www.youtube.com/",
    },
    {
      icon: <img src={TwitterIcon} />,
      link: "https://twitter.com/",
    },
    {
      icon: <img src={WhatsAppIcon} />,
      link: "",
    },
  ];

  const selectdata = [
    { value: 1, select: "Inquiry types" },
    { value: "General Queries", select: "General Queries" },
    { value: "Services/Activities", select: "Services/Activities" },
    // { value: "Masjid Tour", select: "Masjid Tour" },
    { value: "Donations", select: "Donations" },
    { value: "Volunteer", select: "Volunteer" },
    { value: "Media/Press", select: "Media/Press" },
  ];

  return (
    <div>
      {open && (
        <SuccessAlert
          msg={`Thank You For Contacting Us! You will receive an email confirmation shortly at ${currentEmail}`}
          open={open}
          setOpen={setopen}
        />
      )}
      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="40px"
        Container={
          <div className="SectionContainer">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <ContactUsThree
                  title={"Contact Us"}
                  //description={"We work directly with founders & managers and are 100% email-based. No more time wasted scheduling calls."}
                  onContactFormSubmit={onContactFormSubmit}
                  selectdata={selectdata}
                  ButtonLabel="SEND MESSAGE"
                  ButtonColor="#33B651"
                  error={false}
                  //   disableBtn="false"
                  //   phoneNumberError="error"
                />
                {/* <ContactUsOne
                                    onContactFormSubmit={onContactFormSubmit}
                                    selectOptions={[{ title: "General Queries" }, { title: "Services/Activities" }, { title: "Masjid Tour" }, { title: "Donations" }, { title: " Volunteer" }, { title: "Media/Press" }]}

                                    disableBtn="disabled"

                                    //// Button Style ////
                                    buttonLebel="SEND MESSAGE"
                                    
                                    target="blank"
                                    path="/"
                                    arrow={false}
                                    arrowColor="#fff"
                                    buttoncolor="#0053A5"
                                    width="100%"
                                    height="47px"
                                    border="#ccc 0px solid"
                                    borderRadius="4px"
                                    colorlabel="#fff"
                                    fontWeight="600"
                                    textAlign="center"
                                /> */}
              </Grid>

              <Grid item xs={12} md={6}>
                <ContactInfoOne
                  img={officeImg}
                  title="OTHER WAYS TO CONNECT"
                  titleColor="#56B149"
                  contactDetails={contactDetails}
                  socialLinks={socialLinks}
                  imgStatus={true}
                />
              </Grid>
            </Grid>
          </div>
        }
      />
    </div>
  );
}

export default ContactUs;
