import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import OurProjectPage from "./components/OurProjectPage";
import AboutUsPage from "./components/AboutUsPage";
import NewsPage from "./components/NewsPage";
// import USAdistributionofmaterialsNewsPage from "./components/USAdistributionofmaterialsNewsPage";
import ArticleDetailsPage from "./components/ArticleDetailsPage";




import ArtificialLimbSupportProgramDetailsPage from "./components/ArtificialLimbSupportProgramDetailsPage";
import EducationSupportProgramDetailsPage from "./components/EducationSupportProgramDetailsPage";
import DisasterManagementProgramDetailsPage from "./components/DisasterManagementProgramDetailsPage";
import MedicalSupportProgramDetailsPage from "./components/MedicalSupportProgramDetailsPage";
import WheelChairforHandicappedProgramDetailsPage from "./components/WheelChairforHandicappedProgramDetailsPage";
import WinterAssistanceAndHungerProgramDetailsPage from "./components/WinterAssistanceAndHungerProgramDetailsPage";



import Tos from "./components/Tos";
import PhotoGallery from "./components/PhotoGalleryPage";

function App() {
  return (
    <Routes>
      <Route exact path={"/"} element={<HomePage />} />
      <Route path={"/our-projects"} element={<OurProjectPage />} />
      <Route path={"/about-us"} element={<AboutUsPage />} />

      {/* ---------News---------- */}
      <Route path={"/news"} element={<NewsPage />} />
      {/* <Route path={"/usa-distribution-of-protective-material-news"} element={<USAdistributionofmaterialsNewsPage />} /> */}


      {/* -------------------------------- */}
      <Route path={"/article-details"} element={<ArticleDetailsPage />} />

      <Route path={"/education-support-program-details"} element={<EducationSupportProgramDetailsPage />} />

      <Route path={"/artificial-limb-support-program-details"} element={<ArtificialLimbSupportProgramDetailsPage />} />
      <Route path={"/disaster-management-program-details"} element={<DisasterManagementProgramDetailsPage />} />
      <Route path={"/medical-support-program-details"} element={<MedicalSupportProgramDetailsPage />} />
      <Route path={"/wheel-chair-for-handicapped-program-details"} element={<WheelChairforHandicappedProgramDetailsPage />} />
      <Route path={"/winter-assistance-and-hunger-program-details"} element={<WinterAssistanceAndHungerProgramDetailsPage />} />



      <Route path={"/Terms-Policies"} element={<Tos />} />
      <Route path={"/photo-gallery"} element={<PhotoGallery />} />
    </Routes>
  );
}

export default App;
