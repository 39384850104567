import React, { createRef, useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Grid from '@material-ui/core/Grid';

import AboutBanner from '../assets/imgs/AboutBanner.png';


import { Section, PageTitleOne, } from "master-react-lib";
import CTAOneSection from './CTAOneSection'
import BoardofAdvisors from './BoardofAdvisors'
import BoardofDirectors from './BoardofDirectors'
import ExecutiveTeam from './ExecutiveTeam'
import ContactUs from './ContactUs'
import Pattern from "../assets/imgs/./RepeatPattern.svg";

import '../assets/css/style.css'

function AboutUsPage(props) {

  const missionRef = createRef();
  const visionRef = createRef();
  const teamRef = createRef();
  const contactRef = createRef();


  useEffect(() => {
    setTimeout(() => {
      if (window.location.href.indexOf("#mission") > -1) {
        missionRef && missionRef.current.scrollIntoView({ behavior: "smooth" });
      }

      else if (window.location.href.indexOf("#vision") > -1) {
        visionRef && visionRef.current.scrollIntoView({ behavior: "smooth" });
      }

      else if (window.location.href.indexOf("#team") > -1) {
        teamRef && teamRef.current.scrollIntoView({ behavior: "smooth" });
      }

      else if (window.location.href.indexOf("#contact-us") > -1) {
        contactRef && contactRef.current.scrollIntoView({ behavior: "smooth" });
      }

      else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
      }
    }, 1000);

  }, [missionRef, visionRef, teamRef]);

  return (

    <div>
      <Header />

      <div className='InnerPageCtn'>
        <PageTitleOne
          Title="About Us"
          color="#fff"
          height="320px"
        />

        <Section
          background="#F6FBFE"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className='SectionContainer'>

              <div className='AboutUSbanner' style={{ marginTop: "-300px" }}>
                <img src={AboutBanner} />

              </div>

              <div className='AboutUSContent'>
                <h1 className='2ndTitle'>Helping Hand Chattogram (HHC-USA)</h1>
                <p>Helping Hand For Chittagonian INC. (HHC), DBA: Helping Hand Chattogram-USA is a US base 501 (c)(3) Not for Profit organization that provides emergency aid, medical equipments to Covid 19 Victims , Education support, Disaster management, Feed the hunger, Rehabilitation program, Developmental assistance, and services to vulnerable populations in Bangladesh as well as in the USA. Our project helps to raise Community Awareness, Medical Assistance, Education Activities and support emergency aid to Covid 19 Victims in home and abroad.   </p>

                <p>The goal of community awareness is to increase the community’s knowledge of the available programs and services offered.Currently, the HHC operates its projects in Bangladesh through “the local Helping Hand- Chattogram chapter in Bangladesh. A group of dedicated volunteers runs this organization. The activities of the HHC are inspected and audited by the respective regulatory authorities in the jurisdictions in which it operates.</p>

              </div>


              <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                  <div className='MssionCard' ref={missionRef}>
                    <h3>Our mission</h3>
                    <p>The primary objective of the HHC is to help those who are suffering from poverty, hunger, disease, illiteracy, discrimination, homelessness, debt, unemployment, deprivation or lack of skills,
                      economic opportunities, and numerous anomalous social conditions. Our Project helps to raise Community Awareness, Education Activities, and health care programs to reduce and promote family unity & values. its volunteer-driven field of focus has been broadened. </p>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className='VisionCard' ref={visionRef}>
                    <h3>Our Vision</h3>
                    <p>Our vision is solely dedicated to reducing human suffering, that began its journey with support an emergency aid, medical equipments to Covid 19 Victims along with  Scholarship program, Disaster management, Feed the hunger in home and abroad. a unique supported by individual donors . Helps to raise Community Awareness, Education Activities through the passage of time.
                    </p>
                  </div>
                </Grid>

              </Grid>



              <div>



              </div>
            </div>
          }
        />

        {/* <BoardofAdvisors /> */}
        <BoardofDirectors /> 
        <div ref={teamRef}>
          <ExecutiveTeam />
        </div>
        <div ref={contactRef}>
          <ContactUs />
        </div>
        <CTAOneSection />

      </div>
      <Footer />
    </div>

  );
}


export default AboutUsPage;